import { Box, Chip, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import HttpProvider from 'services/HttpProvider';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { LineChart } from '@mui/x-charts/LineChart';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import axios from 'axios';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import myUserStore from '../../stores/UserStore';
import { useHistory } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import TrackMapEarth from './TrackMapEarth';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
export default function MarkOptimization({
  track1,
  hoverLatitude,
  hoverLongitude,
  trackIndex,
  removeMap = () => {},
  race,
  driverSessions,
  colorVector,
  day
}) {
  const [chartSize, setChartSize] = useState({
    width: window.innerWidth < 500 ? 285 : 500,
    height: window.innerWidth < 500 ? 199.5 : 300
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [sectorAnalytics, setSectorAnalytics] = useState('');

  const [showSectors, setShowSectors] = useState(true);
  const [showSectorNumbers, setShowSectorNumbers] = useState(false);
  const [ballColors, setBallColors] = useState([]);
  const [sectorParts, setSectorParts] = useState([]);
  const [track, setTrack] = useState([]);
  const [trackData, setTrackData] = useState([]);
  const history = useHistory();
  const [competitions, setCompetitions] = useState([]);
  const [center, setCenter] = useState([]);

  const [centroids, setCentroids] = useState([]);
  const selectedTeam = myUserStore.getSelectedTeam;
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  let paddingBlock = 49.2;
  let paddingInline = 49.2;
  const user = JSON.parse(localStorage.getItem("user_data") || "{}");
  const showPersonalButton = myUserStore.isPersonalSpace;
console.log ( "sectors", showSectorNumbers)
  console.log("track index", trackIndex * 4);

  // ---------------------
  // FUNZIONI E FETCH VARI
  // ---------------------
  const fetchCompetitions = async () => {
    return HttpProvider.get(
      `/get_competitions/?team_id=${selectedTeam.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      }
    )
      .then((res) => {
        setCompetitions(res.data);

        // Trova l'oggetto che ha l'id uguale a race e imposta setTrack
        const competition = res.data.find((comp) => comp.id === race);
        if (competition) {
          setTrack(competition.track);
        }
setSectorAnalytics(competition.track);
        getCentroids(competition.track);
        getLongLatTrack(competition.track);
      })
      .catch((err) => {
        console.error('Error fetching competitions:', err);
      });
  };
  const showButtons =  (open) => {
  setIsHovered(open)
  };
  const getCentroids = async (givenTrack) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/centroids_data/?track=${givenTrack}`
      );
      const data = response.data;
      setCentroids(data);
    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  };

  const getLongLatTrack = async (givenTrack) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/long_lat_for_track/?track=${givenTrack}`
      );
      const data = response.data;
      const filteredData = data.filter((_, index) => index % 8 === 0);
      setTrackData(filteredData);
      console.log("Filtered Track Data:", filteredData);
    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  };

  const getSectors = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_change_in_sector?race=${race}&&day=${day}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((userResponse) => {
        const sectorTimes =
          userResponse.data[0]?.sectorData?.sector_times || [];
        const roundedSectorParts = sectorTimes.map((time) =>
          Math.round(time / 0.16)
        );
        setSectorParts(roundedSectorParts);
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  };

  const getTrackData = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_filtered_longitude_latitude?race=${race}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((userResponse) => {
        setIsLoading(false);
        const firstMultiTrajectory = userResponse.data[0].drivingData.map(pt => ({
          lat: pt.latitude,
          lon: pt.longitude
        }));
      
        const center1 = [
          firstMultiTrajectory[0].lat,
          firstMultiTrajectory[0].lon
        ];
        setCenter ( center1)
        setDataset(userResponse.data);
        getSectors();
      })
      .catch((error) => {
        console.error("Query Error:", error);
        setIsLoading(false);
      });
  };

  // ---------------------
  // USE EFFECT
  // ---------------------
  useEffect(() => {
    getTrackData();
  }, [chartSize, driverSessions, track]);

  useEffect(() => {
    fetchCompetitions();
  }, [chartSize, driverSessions, track1]);

  useEffect(() => {
    const generateRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const colors = dataset.map(() => generateRandomColor());
    setBallColors(colors);
  }, [dataset]);

  

  // ---------------------
  // FUNZIONI PER ZOOM E NAVIGAZIONE
  // ---------------------
  
  const increaseSize = () => {
    if (chartSize.width < window.innerWidth) {
      setChartSize((prevSize) => ({
        width: prevSize.width * 1.1,
        height: prevSize.height * 1.1,
      }));
      paddingBlock = paddingBlock * 1.1;
      paddingInline = paddingInline * 1.1;
    }
  };

  const handleSector = () => {
   
    setShowSectorNumbers(!showSectorNumbers)
  };
  const decreaseSize = () => {
    
    setChartSize((prevSize) => ({
      width: Math.max(prevSize.width * 0.9, 100),
      height: Math.max(prevSize.height * 0.9, 100),
    }));
  };

  const navigateToTelemetryData = () => {
    history.push('/racing-space/telemetry-data');
  };

  // ---------------------
  // RENDER
  // ---------------------
  return (
    <Draggable
      bounds=".draggable-parent"
      handle=".drag-handle"
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          zIndex: 991,
          borderRadius: 5,
          backgroundColor: '#fff',
          border: '1px solid #dedede'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onTouchStart={() => setIsHovered(true)}
        ref={chartRef}
      >
        {/* --- ICONA PER IL DRAG SOLO DA QUI (OpenWith) --- */}

        {/* CONTENUTO */}
        {!isLoading && (dataset[0]?.drivingData?.length > 0 && trackData?.length > 0) ? (
          <Box
            sx={{
              width: window.innerWidth < 1000 ? chartSize.width : chartSize.width,
              height: chartSize.height,
              overflow: 'hidden',
              position: 'relative',
              borderRadius: 5
            }}
          >
            {/* Mostriamo la mappa (TrackMapEarth) con i dati */}
            <TrackMapEarth colorVector={colorVector} showSectors={showSectorNumbers}isAnalytics={true} notSearch={true} differentTrack={sectorAnalytics} driversData={dataset} trackIndex={trackIndex} center={center} revealButtons={showButtons}/>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: chartSize.width,
              height: chartSize.height,
              backgroundColor: '#f0f0f0',
              borderRadius: '8px',
            }}
          >
            {isLoading ? (
              'Loading data...'
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: chartSize.width,
                  height: chartSize.height,
                  backgroundColor: '#f0f0f0',
                  borderRadius: '8px',
                  flexDirection: 'column',
                }}
              >
                <Typography>No telemetry data inserted yet!</Typography>
                <Chip
                  label="Insert Data"
                  color="primary"
                  onClick={navigateToTelemetryData}
                  sx={{ mt: 2 }}
                />
              </Box>
            )}
          </Box>
        )}

        {/* Mostra i pulsanti ZoomIn / ZoomOut solo quando isHovered=true */}
        {isHovered && (
          <div
            style={{
              position: 'absolute',
              top: 5,
              right: 5,
              zIndex: 9999,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {/* Icona DRAG (OpenWith) dentro cerchio bianco, nera, draggable-handle */}
            <IconButton
              className="drag-handle"
              size="small"
              style={{
                cursor: 'grab',
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '50%',
              }}
            >
              <OpenWithIcon />
            </IconButton>

            {/* Zoom In (solo se lo schermo è abbastanza grande) */}
            {chartSize.width < window.innerWidth && (
              <IconButton
                onClick={increaseSize}
                size="small"
                sx={{ zIndex: 300 }}
                onTouchStart={increaseSize}
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  borderRadius: '50%',
                }}
              >
                <ZoomInIcon />
              </IconButton>
            )}

            {/* Zoom Out */}
            <IconButton
              onClick={decreaseSize}
              size="small"
              sx={{ zIndex: 300 }}
              onTouchStart={decreaseSize}
              style={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '50%',
              }}
            >
              <ZoomOutIcon />
            </IconButton>

            <IconButton
              onClick={handleSector}
              size="small"
              style={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '50%',
              }}
            >
              <ControlCameraIcon />
            </IconButton>

            {/* Close Icon (console.log) */}
            <IconButton
              onClick={() => removeMap()}
              size="small"
              style={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '50%',
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}

      </Box>
    </Draggable>
  );
}
