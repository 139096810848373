import { Accordion, AccordionDetails, AccordionSummary, Box, Chip} from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';

import Telemetrytable from 'components/pages/Telemetrytable';
import { observer } from "mobx-react-lite";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const SelectTelemetry = ({
  selectedTeam = {},
  activeRace = 0,
  activeDay = 0,
  activeDriver = [],
  laps =  [],
  driverSessions = [],
  onChange = () => { },
  setLapTimesMap,
  completed,
  selectedDriver,
  onChangeDriverSessions = () => { },
  isTry,
  ...props
}) => {

console.log('active driver', activeDriver)
  
  const handleLapsSelection = (TelemetryLaps) => {
    let newLaps
    newLaps = TelemetryLaps(laps) 
    onChange(newLaps);
  }
  const [isMobile, setIsMobile] = useState(window.innerWidth < 560);
  const [isMobileTable, setIsMobileTable] = useState(window.innerWidth < 430);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 560);
      setIsMobileTable(window.innerWidth < 430);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDriverSessionsSelection = (TelemetryDriverSessions) => {
  
    let newSessions
    newSessions = TelemetryDriverSessions(driverSessions) 
    onChangeDriverSessions(newSessions);
  }

  // [{"session":1,"laps":[2,3]}]

  const sessionNumbersByDriver = useMemo(() => {
    let session = {}
    activeDriver.forEach((driver) => {
      session[driver.value] = [driver.sessions?.[0]?.session || 1]
    })
    return session
  }, [activeDriver])
// {...(selectedDriver ? { expanded: selectedDriver === driver.label } : {})}
  return (
    <Box textAlign="center"sx={{...(isMobileTable && { width: '122%', marginLeft: -3.5 }), }} {...props}>
      {
        activeDriver.map((driver) => (
          <Accordion key={driver}  sx={{marginBottom:2}} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              
              {driver.label}
            </AccordionSummary>
            <AccordionDetails>
            {/* {isMobile && <p>SWIPE LEFT TO CHOOSE THE LAPS</p>} */}
              <Telemetrytable
                race={activeRace}
                day={activeDay}
                drivers={activeDriver}
                laps={laps}
                driverSessions={driverSessions}
                setLapTimesMap={setLapTimesMap}
                fullName={driver.label}
                selectedDriverEmail={driver.value}
                setLaps={handleLapsSelection}
                completed={completed}
                selectedDriver = {selectedDriver}
                setDriverSessions={handleDriverSessionsSelection}
                wait={selectedDriver}
                isTry={isTry}
                //driverSessions={activeDriver}
                sessionNumbersByDriver={sessionNumbersByDriver}
              />
            </AccordionDetails>
          </Accordion>
        ))
      }
    </Box>
  );
};

export default observer(SelectTelemetry);