import React from 'react';
import { Box, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";

const BackgroundContainer = styled(Box)(({ theme }) => ({
  background: '#000', // Black background
  display: 'flex',
  flexDirection: 'column', // Column layout for stacking
  justifyContent: 'center', // Default center alignment
  alignItems: 'center',
  height: '45vh', // Fixed height
  color: '#fff',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '20px', // Add padding for better mobile spacing
  },
}));

const GradientText = styled('span')(({ theme }) => ({
  background: 'linear-gradient(90deg, #3A49F9, #A038F5)', // Blue to vibrant purple gradient
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
  fontSize: '3rem', // Default size for large devices
  [theme.breakpoints.down('md')]: { // Responsive adjustments for medium devices
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: { // Adjust further for small devices
    fontSize: '2rem',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(90deg, #3A49F9, #A038F5)', // Blue to vibrant purple gradient
  color: '#fff',
  borderRadius: '50px', // More rounded appearance
  padding: '10px 20px', // Smaller padding for compact design
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginTop: '10px',
  '&:hover': {
    background: 'linear-gradient(90deg, #2C3ADF, #8A2BE2)', // Slightly darker hover gradient
  },
  [theme.breakpoints.down('sm')]: { // Responsive adjustments for mobile
    fontSize: '1rem',
    padding: '8px 16px',
  },
}));

const CallToActionSection = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Checks if screen is medium
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Checks if screen is small
  const history = useHistory(); // Hook for navigation

  const handleButtonClick = () => {
    history.push('/sign-up'); // Navigate to the sign-up route
  };

  return (
    <BackgroundContainer>
      <Typography
        variant={
          isSmallScreen ? 'h4' : isMediumScreen ? 'h4' : 'h3' // h3 for large, h4 for medium, h5 for small
        }
        sx={{
          fontWeight: 'bold',
          textAlign: 'center', // Center text alignment
          mb: 2,
        }}
      >
        <GradientText>Sign Up for free</GradientText> to discover AiMotor
      </Typography>
      <StyledButton variant="contained" onClick={handleButtonClick}>
        Get Started
      </StyledButton>
    </BackgroundContainer>
  );
};

export default CallToActionSection;
