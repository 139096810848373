import '../components.css';

import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import {
  Checkbox, FormControlLabel, useMediaQuery, useTheme,
  Select, SelectChangeEvent, MenuItem, Button, Typography,
  Grid, Box, Dialog, DialogTitle, DialogContent, DialogActions,
  Chip, Backdrop, Skeleton, IconButton, FormControl, InputLabel
} from '@mui/material';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import { Link, NavLink, Route, Switch, useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Pricing from "./Pricing.js";
import AddIcon from '@mui/icons-material/Add';
import PaypalProvider from "services/PaypalProvider";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import myUserStore from 'stores/UserStore';
import zIndex from '@mui/material/styles/zIndex';
import { FaCopy } from 'react-icons/fa';
import DailyIframe from '@daily-co/daily-js';  // <--- IMPORT DAILY
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';

const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const SECRET = process.env.REACT_APP_PAYPAL_SECRET;

function PageHeader({ isSettings1 }) {
  const history = useHistory();
  const { teamId } = useParams();
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));

  // Stato relativo ai driver e al team
  const [drivers, setDrivers] = useState([]);
  const [team, setTeam] = useState({});
  const [driverRoles, setDriverRoles] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [addMember, SetAddMember] = useState(false);
  const [addEmail, SetAddEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);

  const [wantToDelete, setWantToDelete] = useState(false);
  const [wantToAbandon, setWantToAbandon] = useState(false);
  const [wantToRemove, setWantToRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDriver, setIsLoadingDriver] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [changedRole, setChangedRole] = useState("");
  const [showRemoveMembers, setShowRemoveMembers] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showPricing, setShowPricing] = useState(false);
  // Subito prima o dopo la dichiarazione di "selectedDriver"
  const [tempName, setTempName] = useState("");
  const [tempSurname, setTempSurname] = useState("");
  const [didChange, setDidChange] = useState(false);

  const [showCreateMemberDialog, setShowCreateMemberDialog] = useState(false);

  // Campi per name, surname, email
  const [createName, setCreateName] = useState("");
  const [createSurname, setCreateSurname] = useState("");
  const [createEmail, setCreateEmail] = useState("");
  const [showDowngradeDialog, setShowDowngradeDialog] = useState(false);

  // Controllo se ho tutti i campi
  const isAllFilled = createName && createSurname
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const myPlan = myUserStore.planCode || "normal";
  // Stato per mostrare/nascondere le checkbox nei driver slots
  const [showDeleteSlots, setShowDeleteSlots] = useState(false);

  // Stato con le email dei driver slots selezionati per la cancellazione
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [showUpgradeMemberDialog, setShowUpgradeMemberDialog] = useState(false);

  // Esempio di funzione che apre il dialog dopo il "Complete" dell'aggiunta
  const handleCompleteAddMember = () => {
    if (teamMembers && teamMembers.length > 0) {
      // Se esiste almeno uno slot, avvia il processo di upgrade
      setShowUpgradeMemberDialog(true);
    } else {
      // Se non ci sono slot, crea direttamente il team member
      handleCreateMember();
    }
  };

  // Quando l’utente spunta o rimuove la spunta da una checkbox
  const handleSlotsCheckbox = (email) => {

    setSelectedSlots((prev) => {
      // Se l’email era già selezionata, vuol dire che la stiamo deselezionando
      if (prev.includes(email)) {
        return [];
      }
      // Altrimenti selezioniamo solo questa nuova email (e rimuoviamo ogni altra selezione)
      return [email];
    });
  };
  // Sostituisci la tua handleDialogOpen1 con questa,
  // così inizializzi tempName e tempSurname appena apri il dialog
  const handleDialogOpen1 = (member) => {
    setSelectedDriver(member);
    setTempName(member.name || "");
    setTempSurname(member.surname || "");
    setDidChange(false); // resettiamo ogni volta che apriamo
    setOpenDialog1(true);
  };

  const handleDialogClose1 = () => {
    setOpenDialog1(false);
    setSelectedDriver(null);
  };
  // Funzione che, se non ci sono slot selezionati, mostra solo le checkbox.
  // Se invece ci sono slot selezionati, chiama la DELETE.
  const handleDeleteSlots = async () => {
    debugger
    // 1) Se non siamo in “delete mode”, mostriamo le checkbox
    // if (!showDeleteSlots) {
    //   setShowDeleteSlots(true);
    //   return;
    // }

    // 2) Se siamo in “delete mode” ma nessuno è selezionato => annullo
    if (showDeleteSlots && selectedSlots.length === 0) {
      setShowDeleteSlots(false);
      return;
    }
    // Funzione che apre il dialog e setta i campi locali
    const handleDialogOpen1 = (member) => {
      setSelectedDriver(member);
      setTempName(member.name);       // <-- inizializzi con name esistente
      setTempSurname(member.surname); // <-- inizializzi con surname esistente
      setOpenDialog1(true);
    };

    // 3) Se stiamo effettivamente cancellando con X selezionati => 
    //    calcoliamo nuovo prezzo e facciamo la PATCH PayPal
    if (selectedSlots.length > 0) {
      try {
        // Esempio di prezzo base 70€ + 10€ per ogni slot rimanente
        // Se ho teamMembers = 5 e rimuovo 2, dopo ne rimangono 3 => newPrice = 70 + (3 * 10)
        const howManyNow = teamMembers.length;
        const howManyAfter = howManyNow - selectedSlots.length;
        const newPrice = 70 + (howManyAfter * 10);

        // Patch data
        const updateData = [
          {
            op: "replace",
            path: "/plan/billing_cycles/@sequence==2/pricing_scheme/fixed_price",
            value: {
              currency_code: "EUR",
              value: newPrice
            }
          },
          {
            op: "replace",
            path: "/custom_id",
            value: "Team"
          }
        ];

        // Patch PayPal
        await updateSubscription(updateData, true);

        deleteMemberAfterPlan();



        toast.info(`Plan downgraded to €${newPrice}, now removing selected members...`);
        // “updateSubscription” in "finally" chiama getPaypalSubscription(subscriptionId),
        // e lì dentro, al termine, rimuoviamo i membri uno per uno.

        // Se preferisci rimuoverli “qui”, ti basta invocare un for su selectedSlots e 
        // chiamare “deleteMemberAfterPlan(...)” per ognuno.
        // Ad esempio:
        /*
        for (const email of selectedSlots) {
          // Nel tuo schema “deleteMemberAfterPlan” 
          // si aspetta l’ID? O l’email? Adatta di conseguenza
          await deleteMemberAfterPlan(email);
        }
        toast.success(`All selected members removed!`);
        window.location.reload();
        */

        // Se invece preferisci rimuoverli in getPaypalSubscription, 
        // passa il “selectedSlots” come arg e falli lì.

        setShowDeleteSlots(false);
        setSelectedSlots([]);

      } catch (error) {
        console.error("Error downgrading or removing members:", error);
        toast.error("Cannot downgrade or remove members");
      }
    }
  };

  const handleShowDelete = async () => {
    // Se non siamo in “delete mode”, mostriamo le checkbox
    if (!showDeleteSlots) {
      setShowDeleteSlots(true);
      return;
    }
    // Se siamo in “delete mode” ma nessuno è selezionato => annullo
    if (showDeleteSlots && selectedSlots.length === 0) {
      setShowDeleteSlots(false);
      return;
    }
    // Se sono stati selezionati dei membri, apri il dialog di downgrade
    if (selectedSlots.length > 0) {
      setShowDowngradeDialog(true);
      return;
    }
  };

  // Esempio di funzione di patch PayPal (riusa la stessa di Plans)
  // Esempio: calcola il prezzo finale come base di 70 + 10 euro per ogni chiave
  const handleUpgradePlanExtra = async () => {
    // Sostituisci 'teamKeys' con la variabile che ti indica il numero di chiavi 
    // (ad es. 3 se hai 3 chiavi).
    const teamKeys = teamMembers?.length;
    const newPrice = 70 + (teamKeys * 10);

    const updateData = [
      {
        "op": "replace",
        "path": "/plan/billing_cycles/@sequence==2/pricing_scheme/fixed_price",
        "value": {
          "currency_code": "EUR",
          "value": newPrice,  // <-- ecco il prezzo calcolato
        },
      },
      {
        "op": "replace",
        "path": "/custom_id",
        "value": "Team" // o il nome personalizzato del piano
      }
    ];

    try {
      await updateSubscription(updateData);
      handleCreateMember();


      toast.success(`Subscription upgraded to ${newPrice}€!`);
      setShowUpgradeMemberDialog(false);
      // Se vuoi ricaricare la pagina o ricaricare i dati:
      // window.location.reload();
    } catch (error) {
      console.error("Error upgrading plan by +X€:", error);
      toast.error("Cannot upgrade plan!");
    }
  };

  const updateSubscription = async (updateData, remove) => {

    const token = await getToken();
    const subscriptionId = myUserStore.subscriptionDetails?.id
    debugger
    if (token) {
      try {
        const response = await axios.patch(`https://api-m.paypal.com/v1/billing/subscriptions/${subscriptionId}`, updateData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        console.log('Updated Subscription:', response.data);
        return response.data;
      } catch (error) {
        console.error('Error updating subscription:', error);
      } finally {
        getPaypalSubscription(subscriptionId, remove);
      }
    }
  };
  const getPaypalSubscription = async (subId, userIdToDelete) => {
    const token = await getToken();
    if (token) {
      return PaypalProvider.get(`billing/subscriptions/${subId}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      })
        .then(async (res) => {
          const subs = res.data;

          // 1) POST subscription su /post_subscription
          await postSubscription(subs);

          // 2) Subito dopo: chiami l’endpoint delete_member
          // se la function ha un `userIdToDelete` (o un `emailToDelete`) passata


          // 3) Se vuoi reload:
          // window.location.reload();
          return subs;
        })
        .catch((err) => {
          console.error("Error getting subscription details:", err);
          return {};
        });
    } else {
      return {};
    }
  };


  // 4) createTeamMember sfrutta i campi createName, createSurname, createEmail

  const getToken = async () => {

    try {
      const response = await PaypalProvider.post('oauth2/token',
        'grant_type=client_credentials',
        {
          auth: {
            username: CLIENT_ID,
            password: SECRET
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );
      console.log('Token:', response.data.access_token);
      return response.data.access_token;
    } catch (error) {
      console.error('Error getting token:', error);
    } finally {

    }
  };

  // Esempio di "downgrade plan": prezzo base 70 + 10*(teamKeys - 1)
  const handleDowngradePlanExtra = async (userIdToDelete) => {
    // teamKeys = lunghezza attuale di teamMembers
    const teamKeys = teamMembers?.length || 0;
    // Ad es: se teamKeys = 5, allora newPrice = 70 + (5 - 1)*10 = 70 + 40 = 110
    const newPrice = 70 + (teamKeys - 1) * 10;

    const updateData = [
      {
        "op": "replace",
        "path": "/plan/billing_cycles/@sequence==2/pricing_scheme/fixed_price",
        "value": {
          "currency_code": "EUR",
          "value": newPrice,
        },
      },
      {
        "op": "replace",
        "path": "/custom_id",
        "value": "Team"
      }
    ];

    try {
      await updateSubscription(updateData);
      toast.success(`Subscription downgraded to ${newPrice}€!`);
      setShowUpgradeMemberDialog(false);
      // Non ricarichiamo la pagina qui; proseguiamo in getPaypalSubscription
    } catch (error) {
      console.error("Error downgrading plan:", error);
      toast.error("Cannot downgrade plan!");
    }
  };


  const handleCreateMember = async () => {
    const customEmail = `${createName}.${createSurname}.${teamMembers.length}.${parsedUserData.user_id}@aimotor.eu`;
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/create_team_member/?name=${createName}&surname=${createSurname}&email=${customEmail}`;
      await axios.post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      // Se la creazione va a buon fine, ricarico la pagina
      window.location.reload();
    } catch (error) {
      console.error("Error creating team member:", error);
      toast.error("Error creating team member");
    }
  };


  // const status = myUserStore.status
  //   const [myPlan, setMyPlan] = useState(status != 'CANCELLED' ? myUserStore?.subscriptionDetails?.custom_id : 'No Plan');

  const isTeam = myPlan == "Team"
  console.log("is team", isTeam)
  // Stato per i meeting
  const [teamMeetings, setTeamMeetings] = useState([]);

  // --- STATI & LOGICA PER LA CALL DAILY --- //
  const [callLink, setCallLink] = useState('');     // Link Daily
  const [callFrame, setCallFrame] = useState(null); // Istanza daily
  const [inCall, setInCall] = useState(false);      // true = mostra la call a schermo intero
  const [error, setError] = useState('');

  // GET /get_team_room
  useEffect(() => {
    const fetchTeamRoom = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_team_room/?team_id=${parseInt(teamId)}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        setTeamMeetings(res.data);
        setCallLink(res.data?.meeting_info?.url);
      } catch (error) {
        console.error("Errore durante la GET /get_team_room:", error);
      }
    };
    fetchTeamRoom();
  }, [teamId]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_team_members/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        setTeamMembers(Object.entries(response.data));
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    // Call immediately on mount
    fetchTeamMembers();
  }, []);
  const postSubscription = async (subscriptionDetails) => {
    console.log("details recieved", subscriptionDetails)
    const user = JSON.parse(localStorage.getItem("user_data") || {})
    if (user && user.email) {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/post_subscription/`
      await axios.post(
        apiUrl,
        {
          user_id: user.user_id,
          user_email: user.email,
          subscriptionDetails
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then(
          (response) => {

            myUserStore.setUserSubscriptionDetails(subscriptionDetails);
            debugger

          }


        )
        .catch(
          (error) => {
            console.error("Error!", error)
          }
        )
    } else {
      console.error('no email found')
      toast.error("Email not found!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }

  };


  const createTeamMember = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/create_team_member/?name=team7&surname=member1&email=team.member1.${parsedUserData.user_id}@aimotor.eu`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      console.log("Team member created:", response.data);
    } catch (error) {
      console.error("Error creating team member:", error);
    }
  };

  // POST /create-room
  const createRoom = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/create_room/?team_id=${parseInt(teamId)}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      // Togli setCallLink e setInCall
      // Ritorna il risultato
      return res;
    } catch (err) {
      console.error("Errore durante la POST /create-room:", err);
      setError('Errore nella creazione della stanza');
      throw err; // rilancia l'errore per gestirlo fuori
    }
  };


  // Quando entriamo in call, o si è cliccato su "CREATE MEETING ROOM"
  // creiamo (o joiniamo) l'iframe Daily
  // joinCall (chiamata quando inCall=true e hai callLink)
  const joinCall = useCallback(() => {
    if (callLink) {
      const container = document.getElementById('daily-call-container');
      const newFrame = DailyIframe.createFrame(container, {
        url: callLink,
        showLeaveButton: false,
        showParticipantsBar: false,
        cssText: `
        #screenshare-controls {
          display: none !important;
        }
      `,

        allowMultipleCallInstances: true,
        iframeStyle: {
          width: '100%',
          height: '100%',
          border: 0
        },



      });
      newFrame.join().then(() => {
        newFrame.updateSendSettings({
          screenVideo: {
            encodings: {
              low: { maxFramerate: 60 },
              medium: { maxFramerate: 60 },
              high: { maxFramerate: 60 }
            },
            maxQuality: 'high'
          },
          video: {
            encodings: {
              low: { maxFramerate: 60 },
              medium: { maxFramerate: 60 },
              high: { maxFramerate: 60 }
            },
            maxQuality: 'high'
          }
        });
      });
      setCallFrame(newFrame);
    }
  }, [callLink]);

  // Effetto che parte quando inCall diventa true (e abbiamo un link)
  useEffect(() => {
    if (inCall && callLink && !callFrame) {
      joinCall();
    }
  }, [inCall, callLink, callFrame, joinCall]);

  const handleStartScreenShare = async () => {
    if (!callFrame) return;
    try {
      await callFrame.startScreenShare({
        screenVideoSendSettings: 'motion-optimized',
      });
      toast.success("Screen sharing started (motion-optimized)!");
    } catch (err) {
      console.error("Errore avvio screenshare:", err);
      toast.error("Cannot start screenshare");
    }
  };



  // Click su "Leave Call"
  const handleLeaveCall = () => {
    if (callFrame) {
      callFrame.leave();
      callFrame.destroy();
    }
    setInCall(false);
    setCallLink('');
    setCallFrame(null);
    window.location.reload();
  };

  // --- FINE LOGICA DAILY --- //

  // Funzioni di trasformazione dati e caricamento team
  const transformData = (data) => {
    const { email, surname, teams, owner, teams_info } = data;
    const selectedTeam = teams_info.find(t => t.team_id === parseInt(teamId));
    const theTeam = teams.find(t => t.team_id === parseInt(teamId));
    const owner_id = theTeam.owner_id;
    let transformedTeamsInfo = {};
    if (selectedTeam) {
      transformedTeamsInfo = {
        id: selectedTeam.team_id,
        name: selectedTeam.team_name,
        role: selectedTeam.role,
        owner_id: owner_id,
        isOwner: owner.some(tt => tt.team_id === selectedTeam.team_id && tt.team_owner === parsedUserData.user_id),
        members: selectedTeam.members,
      };
    }
    return transformedTeamsInfo;
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/get_settings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      setTeam(transformData(res.data));
      setTeamName(transformData(res.data).name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // Controlla se è manager
  const isManager = React.useMemo(() => {
    return  team.role === "MGR";
  }, [team?.role]);

  // Effetto caricamento iniziale
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${parseInt(teamId)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setIsLoadingDriver(true);
        setDrivers(res.data);
        const initialRoles = {};
        res.data.forEach(driver => {
          initialRoles[driver.full_name] = '';
        });
        setDriverRoles(initialRoles);
        setIsLoadingDriver(false);
      })
      .catch((error) => {
        setIsLoadingDriver(false);
        console.error("Axios Error:", error);
      });
  }, [teamId]);
  // Funzione per chiudere la meeting room
  const endMeeting = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_DOMAIN}/close_team_room/?team_id=${parseInt(teamId)}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      toast.success("Meeting ended!");
      // Svuota l’array (o indica che non c’è più una room)
      setTeamMeetings([]);
    } catch (error) {
      console.error("Errore durante la chiusura della meeting room:", error);
      toast.error("Error ending meeting");
    }
  };

  // Gestione schermo mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Cambia ruolo in base alla email
  const handleChangeByEmail = (event, email) => {
    const newRole = event.target.value;
    setDriverRoles(prevState => ({
      ...prevState,
      [email]: newRole,
    }));
    handleChangeRoleByEmail(email, newRole);
  };

  const handleChangeRoleByEmail = async (email, new_role) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/change_role/?team_id=${parseInt(teamId)}&new_role=${new_role}&user_email=${email}`;
      await axios.post(
        apiUrl,
        {
          team_id: parseInt(teamId),
          new_role,
          user_email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      setChangedRole(new_role);
    } catch (error) {
      console.error("Errore durante il cambio ruolo via email:", error);
    }
  };
  let chipLabel = "DELETE MEMBER";
  let chipVariant = "contained";
  if (showDeleteSlots) {
    if (selectedSlots.length === 0) {
      // CANCEL DELETE
      chipLabel = "CANCEL DELETE";
      chipVariant = "outlined";
    } else {
      // DELETE
      chipLabel = "DELETE";
      chipVariant = "contained";
    }
  }

  // Verifica se siamo in un piano "Pro" ma con >5 members
  const fullPro = myPlan === "Pro" && team?.members?.length > 5;

  // Ricarica impostazioni se cambia ruolo o se rimuovo qualcuno
  useEffect(() => {
    getSettings();
  }, [changedRole, wantToRemove]);

  // Carica lista team members
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_teams_members?team_id=${parseInt(teamId)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setIsLoadingDriver(false);
        setDrivers(res.data);
        const initialRoles = {};
        res.data.forEach(driver => {
          initialRoles[driver.full_name] = driver.role;
        });
        setDriverRoles(initialRoles);
      })
      .catch((error) => {
        setIsLoadingDriver(false);
        console.error("Axios Error:", error);
      });
  }, [teamId]);

  // Handler e funzioni varie
  const handleChipClick = (chip) => {
    setSelectedRole(chip);
  };

  const handleWantToDelete = () => {
    setWantToDelete(!wantToDelete);
  };
  const handleWantToRemove = () => {
    setWantToRemove(!wantToRemove);
  };
  const handleWantToAbandon = () => {
    setWantToAbandon(!wantToAbandon);
  };
  const handleWantToAbandonRemove = () => {
    handleDeleteMemberClick(parsedUserData.user_id);
  };
  const handlePricingClose = () => {
    setOpenPrice(!openPrice);
  };

  const changeTeamName = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/change_team_name/?role=MGR&team_id=${parseInt(teamId)}&new_team_name=${teamName}`;
      await axios.post(
        apiUrl,
        {
          team_id: parseInt(teamId),
          new_team_name: teamName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      setShowChangeName(false);
      window.location.reload();
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const handleEditTeamName = () => {
    setShowChangeName(!showChangeName);
  };

  const handleInviteClick = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/invite_member/?role=DRV&team_id=${parseInt(teamId)}&status=PENDING&user_email=${addEmail}`;
      await axios.post(
        apiUrl,
        {
          role: selectedRole,
          team_id: parseInt(teamId),
          status: "PENDING",
          user_email: addEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      toast.success("Member invited!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
      SetAddMember(false);
    } catch (error) {
      if (error.response && error.response.data.detail === "Member already in the Team") {
        toast.error("Member already invited", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
      } else {
        console.error("Error ", error);
      }
    }
  };

  const handleDeleteTeamClick = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/delete_team/?team_id=${parseInt(teamId)}`;
      await axios.post(
        apiUrl,
        {
          team_id: parseInt(teamId),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      history.push(`/racing-space/settings`);
      setWantToDelete(!wantToDelete);
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleChangeRoleClick = async (user_id, new_role) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/change_role/?team_id=${parseInt(teamId)}&new_role=${new_role}&user_id=${user_id}`;
      await axios.post(
        apiUrl,
        {
          team_id: parseInt(teamId),
          new_role: new_role,
          user_id: user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      setChangedRole(new_role);
    } catch (error) {
      console.error("Error while changing the role!", error);
    }
  };

  const handleDeleteMemberClick = async (id) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/delete_member/?user_id=${id}&team_id=${parseInt(teamId)}`;
      await axios.post(
        apiUrl,
        {
          user_id: id,
          team_id: parseInt(teamId),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      setDrivers(drivers.filter(driver => driver.user_id !== id));
      if (wantToRemove) {
        setWantToRemove(!wantToRemove);
      }
      if (wantToAbandon) {
        history.push(`/racing-space/settings`);
        setWantToAbandon(!wantToAbandon);
      }
      window.location.reload();
    } catch (error) {
      console.error("Error", error);
    }
  };
  const deleteTeamMemberDirect = async (email) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_DOMAIN}/delete_team_member/?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      toast.success("Team member deleted successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting team member:", error);
      toast.error("Error deleting team member");
    }
  };
  const deleteTeamMemberDirect1 = async (email) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_DOMAIN}/delete_member/?user_id=${email}&team_id=${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      toast.success("Team member deleted successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting team member:", error);
      toast.error("Error deleting team member");
    }
  };

  const deleteMemberAfterPlan = async (userIdToDelete) => {
    // Adatta all’endpoint che già usi per rimuovere
    try {
      await Promise.all(
        selectedSlots?.map((email) =>
          axios.delete(
            `${process.env.REACT_APP_API_DOMAIN}/delete_team_member/?email=${email}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                accept: "application/json",
              },
            }
          )
        )
      );

      setShowDeleteSlots(false);
      setSelectedSlots([]);

      // **Qui ricarico la pagina**
      window.location.reload();
    } catch (error) {
      toast.error("Error deleting that member");
    }
  };

  const handleChange = (event, fullName, userId) => {
    const selectedRole = event.target.value;
    setDriverRoles(prevState => ({
      ...prevState,
      [fullName]: selectedRole,
    }));
    handleChangeRoleClick(userId, selectedRole);
  };

  const handleAddMemberClick = () => {
    if (!fullPro) {
      SetAddMember(!addMember);
    } else {
      setShowPricing(true);
    }
  };

  const handleCloseAddMember = () => {
    SetAddMember(!addMember);
  };

  const toggleShowAll = () => {
    if (showAll) {
      setShowRemoveMembers(false);
    }
    setShowAll(!showAll);
  };

  const toggleRemoveMembers = () => {
    setShowRemoveMembers(!showRemoveMembers);
    setSelectedMembers([]);
  };

  const handleMemberSelection = (id) => {
    setSelectedMembers(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(memberId => memberId !== id)
        : [...prevSelected, id]
    );
  };

  const confirmRemoveMembers = async () => {
    await removeIt(selectedMembers);
    setShowRemoveMembers(false);
  };

  const removeIt = async (members) => {
    try {
      const promises = members?.map(id => handleDeleteMemberClick(id));
      await Promise.all(promises);
    } catch (error) {
      console.error("Error removing members: ", error);
      toast.error("Error removing members", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  const generateUrl = React.useMemo(() => {
    return `${document.location.origin}/invited-member?teamId=${teamId}`;
  }, [teamId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generateUrl).then(() => {
      alert('Testo copiato negli appunti!');
    }).catch(err => {
      console.error('Errore nella copia del testo: ', err);
    });
  };

  const handleAddEmail = (event) => {
    SetAddEmail(event.target.value);
  };

  const MyFullname = `${parsedUserData.name} ${parsedUserData.surname}`;

  const handleSettingsClick = () => {
    history.push(`/racing-space/settings`);
  };

  const handleNameChange = (event) => {
    setTeamName(event.target.value);
  };


  const style = {
    cursor: 'pointer',
    marginRight: '10px',
    height: isMobile ? '30px' : '22px',
    width: isMobile ? '30px' : '23px',
    color: 'black',
    zIndex: 20000,
  };

  const handleDialogOpen = (driver) => {
    setSelectedDriver(driver);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedDriver(null);
  };

  // --- RENDER: se siamo inCall, mostriamo la stanza Daily a tutto schermo
  if (inCall) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999
        }}
      >
        {/* Contenitore Daily a pieno schermo */}
        <Box
          id="daily-call-container"
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }}
        />

        {/* Icona Screen Share, posizionata in basso, leggermente a destra del centro */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 13,
            left: isMobile ? '50%' : "50%",                  // Spostato un po' a destra del centro
            transform: 'translateX(-50%)' // Centro orizzontale, ma partendo dal 55% 
          }}
        >
          <IconButton
            color="primary"
            onClick={handleStartScreenShare}
            sx={{
              bgcolor: 'white',     // se vuoi uno sfondo bianco
              ':hover': { bgcolor: 'grey.200' },
              borderRadius: '50%',
              boxShadow: 3
            }}
          >
            <PresentToAllIcon fontSize="large" />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          color="error"
          onClick={handleLeaveCall}
          sx={{
            position: 'absolute',
            bottom: 13,
            right: isMobile ? '-40px' : "-30px",                  // Spostato un po' a destra del centro
            transform: 'translateX(-50%)' // Centro orizzontale, ma partendo dal 55% 
          }}
        >
          Leave Call
        </Button>
      </Box>
    );
  }




  // Se non siamo inCall, mostriamo la UI normale:
  return (
    <>
      <Pricing open={showPricing} onClose={() => setShowPricing(false)} />
      <Dialog
        open={addMember}
        onClose={() => SetAddMember(false)}
        maxWidth="sm"
        fullWidth
      >
        <div className="elements-bars" data-palette-mode="dark">
          <div className="frame">
            <p1 className="p">
              COPY INVITATION LINK
            </p1>
            <Chip
              sx={{
                color: "black",
                backgroundColor: "#2f485b",
                display: "flex",
                '@media (max-width: 440px)': { width: "260px" },
              }}
              label={
                <Box
                  onClick={copyToClipboard}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      marginLeft: '8px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                      fontSize: 14,
                      '@media (max-width: 440px)': { fontSize: 13 },
                    }}
                  >
                    {generateUrl}
                  </Box>
                </Box>
              }
            />
            <div className="div">
              <p className="p">
                INVITE BY EMAIL
              </p>
              <div className="div">
                <div className="frame-2">
                  <div className="text-field" data-palette-mode="dark">
                    <div className="content">
                      <div className="input">
                        <div className="content-2">
                          <div className="min-height" />
                          <TextField
                            onChange={handleAddEmail}
                            type="email"
                            value={addEmail}
                            className="text-field-instance"
                            InputLabelProps={{
                              style: { color: '#ffffff' },
                            }}
                            label="EMAIL"
                            variant="filled"
                            size="medium"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select">
                    <div className="content-3">
                      <div className="input-2">
                        <Button
                          color="secondary"
                          size="medium"
                          variant="contained"
                          onClick={handleInviteClick}
                        >
                          INVITE
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="select">
                    <div className="content-3"></div>
                  </div>
                </div>
                {isTeam && (
                  <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                    <Chip
                      label="CREATE TEAM MEMBER"
                      color="primary"
                      icon={<AddIcon sx={{ fontSize: "16px" }} />}
                      onClick={() => setShowCreateMemberDialog(true)}
                      size="medium"
                      sx={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        width: 200
                      }}
                    />


                  </Box>
                )}

              </div>
            </div>
          </div>

          <CloseIcon
            className="design-component-instance-node"
            sx={{ color: 'white', cursor: 'pointer' }}
            onClick={handleCloseAddMember}
            size="medium"
          />
        </div>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            DRIVER INFO
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="body1" sx={{ color: 'blue' }}>
            Email:
          </Typography>
          <Typography variant="body1">
            {selectedDriver?.email}
          </Typography>

          <Button
            variant="contained"
            color="error"
            sx={{ marginTop: 2 }}
            onClick={() => handleDeleteMemberClick(selectedDriver?.user_id)}
          >
            REMOVE MEMBER
          </Button>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20, position: 'relative' }}>
        <Grid container direction="row" spacing={1} justifyContent="space-between" alignContent="center">
          <Grid item>
            {isLoading && (
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={200} />
            )}
            {!isLoading && (
              <Typography sx={{ fontSize: 33, width: '100%' }} className="text-wrapper">
                {isTeam && team.name?.startsWith('personal-') ? 'myTeam' : team.name}
              </Typography>
            )}
          </Grid>
          <Grid item justifyContent="flex-end" sx={{ display: 'flex', alignItems: 'center' }}>
            {!addMember && isManager && (
              <>
                <Button
                  variant="outlined"
                  onClick={handleSettingsClick}
                  startIcon={<ArrowBackIcon />}
                  sx={{ marginRight: 3 }}
                >
                  Go Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleAddMemberClick}
                >
                  ADD MEMBER
                </Button>
              </>
            )}
          </Grid>
        </Grid>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16, marginBottom: 14 }}>
          <Typography sx={{ fontSize: 24, color: 'gray' }}>TEAM MEMBERS</Typography>
          <div>
            {(team?.members?.length > 6) && (
              <Button onClick={toggleShowAll}>
                {showAll ? 'Show Less' : 'Show All'}
              </Button>
            )}
            {/* {isManager && (showAll || (team?.members?.length < 6)) && (
              <Button onClick={toggleRemoveMembers} color='error'>
                {showRemoveMembers ? 'Cancel' : 'Remove Members'}
              </Button>
            )} */}
          </div>
        </div>


        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            justifyItems: 'flex-start',
            gap: '20px'
          }}
        >
          {isLoading && (
            <div>
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
            </div>
          )}
          {!isLoading && (
            <>

              {/* Special team owner */}
              {/* {team?.members?.length > 0 && (
                  <Chip
                    label={team.members[0].full_name}
                    icon={<ManageAccountsIcon sx={{ marginRight: 5 }} />}
                    variant="outlined"
                    size="big"
                    color="info"
                    sx={{ height: 50 }}
                  />
                )} */}

              {/* Team Members with blue border */}

              <Dialog
                open={openDialog1}
                onClose={handleDialogClose1}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {selectedDriver?.name} {selectedDriver?.surname}
                  </Typography>
                </DialogTitle>

                <DialogContent dividers>
                  {/* TextField per il Nome */}
                  <TextField
                    label="Name"
                    variant="filled"
                    fullWidth
                    margin="dense"
                    value={tempName}
                    onChange={async (e) => {
                      const newName = e.target.value;
                      setTempName(newName);
                      setDidChange(true);  // Indichiamo che c’è stato un cambiamento

                      // Chiamata all’endpoint /update_team_member_name/
                      try {
                        await axios.put(
                          `${process.env.REACT_APP_API_DOMAIN}/update_team_member_name/?email=${selectedDriver.email}&new_name=${encodeURIComponent(newName)}`,
                          {},
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                            },
                          }
                        );
                      } catch (err) {
                        console.error(err);
                        toast.error("Error updating name");
                      }
                    }}
                  />

                  {/* TextField per il Cognome */}
                  <TextField
                    label="Surname"
                    variant="filled"
                    fullWidth
                    margin="dense"
                    value={tempSurname}
                    onChange={async (e) => {
                      const newSurname = e.target.value;
                      setTempSurname(newSurname);
                      setDidChange(true);

                      // Chiamata all’endpoint /update_team_member_surname/
                      try {
                        await axios.put(
                          `${process.env.REACT_APP_API_DOMAIN}/update_team_member_surname/?email=${selectedDriver.email}&new_surname=${encodeURIComponent(newSurname)}`,
                          {},
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                            },
                          }
                        );
                      } catch (err) {
                        console.error(err);
                        toast.error("Error updating surname");
                      }
                    }}
                  />

                  {/* Chip Remove Member */}
                  <Box mt={2}>
                    <Chip
                      label="REMOVE MEMBER"
                      icon={<DeleteIcon sx={{ fontSize: 16 }} />}
                      color="error"
                      variant="contained"
                      size="medium"
                      sx={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        fontSize: "0.8rem"
                      }}
                      onClick={() => {
                        if (teamMembers.length === 1) {
                          // Se c'è solo un teamMember, elimina direttamente senza mostrare il dialog
                          deleteTeamMemberDirect(selectedDriver?.email);
                        } else {
                          // Altrimenti, usa il dialog come prima
                          setShowDowngradeDialog(true);
                          handleSlotsCheckbox(selectedDriver?.email);
                        }
                      }}
                    />
                  </Box>


                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => {
                      // Chiudiamo il dialog
                      handleDialogClose1();
                      // Se è stato cambiato qualcosa, ricarichiamo
                      if (didChange) {
                        window.location.reload();
                      }
                    }}
                    color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>



              {/* Remaining Team Members */}
              {(showAll ? team.members : team?.members?.slice(0, 6))?.map((driver, index) => {
                // Se è il primo membro (owner), lo mostro con la Chip "manager"
                if (index === 0) {
                  return (
                    <Chip
                      key={driver.user_id}
                      label={driver.full_name}
                      icon={<ManageAccountsIcon sx={{ marginRight: 5 }} />}
                      variant="outlined"
                      size="big"
                      color="info"
                      sx={{ height: 50 }}
                    />
                  );
                } else {
                  // Membri normali
                  return (
                    <div
                      key={`d-${index}`}
                      style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                    >
                      <FormControl sx={{ minWidth: '150px', maxWidth: '200px', minHeight: 56 }}>
                        <InputLabel>{driver.full_name}</InputLabel>
                        <Select
                          value={driverRoles[driver.full_name] || driver.role}
                          onChange={(event) => handleChange(event, driver.full_name, driver.user_id)}
                          label={driver.full_name}
                          disabled={!isManager} // This line disables the select when not isManager
                        >
                          <MenuItem value="MGR">Manager</MenuItem>
                          <MenuItem value="DRV">Driver</MenuItem>
                        </Select>

                      </FormControl>

                      <IconButton onClick={() => handleDialogOpen(driver)}>
                        <InfoIcon />
                      </IconButton>

                      {/* Checkbox di rimozione multipla, visibile solo se manager e non è l’owner */}
                      {showRemoveMembers && isManager && driver.user_id !== team.owner_id && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedMembers.includes(driver.user_id)}
                              onChange={() => handleMemberSelection(driver.user_id)}
                            />
                          }
                          sx={{ marginLeft: -2.3 }}
                        />
                      )}
                    </div>
                  );
                }
              })}
              {isTeam && teamMembers?.map(([email, member], index) => (
                <div key={`tm-${index}`} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <FormControl sx={{ minWidth: '150px', maxWidth: '200px', minHeight: 56, border: '2px solid lightblue', borderRadius: 2 }}>
                    <InputLabel>{member.name + ' ' + member.surname}</InputLabel>

                  </FormControl>
                  <IconButton onClick={() => handleDialogOpen1({ ...member, email })}>
                    <InfoIcon />
                  </IconButton>
                </div>
              ))}

            </>
          )}
        </div>
        {/* {isTeam && (
          <Box sx={{ maxWidth: 600, position: 'relative', mt: 2 }}>
            <Typography sx={{ fontSize: 24, color: 'gray' }}>
              DRIVERS SLOTS
            </Typography>
          </Box>
        )} */}


        {/* {isTeam && (<div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            justifyItems: 'flex-start',
            gap: '20px',
            marginTop: '1rem'
          }}
        >
          {teamMembers.map(([email, member], index) => {
            const fullName = `${member.name} ${member.surname}`;

            return (
              <div key={index} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <FormControl sx={{ minWidth: '150px', maxWidth: '200px', minHeight: 56 }}>
                  {member.user_id !== team.owner_id ? (
                    <InputLabel>{fullName}</InputLabel>
                  ) : (
                    <Chip
                      label={fullName}
                      icon={<ManageAccountsIcon sx={{ marginRight: 5 }} />}
                      variant="outlined"
                      size="big"
                      color="info"
                      sx={{ height: 50 }}
                    />
                  )}

                
                  {member.user_id !== team.owner_id && (
                    <Select
                      value={driverRoles[email] || member.role}
                      onChange={(event) => handleChangeByEmail(event, email)}
                      label={fullName}
                      disabled={!isManager}
                    >
                      <MenuItem value="DRV">Driver</MenuItem>
                     
                    </Select>
                  )}
                </FormControl>

                <IconButton onClick={() => handleDialogOpen({ ...member, email })}>
                  <InfoIcon />
                </IconButton>

               
                {showDeleteSlots && member.user_id !== team.owner_id && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedSlots.includes(email)}
                        onChange={() => handleSlotsCheckbox(email)}
                      />
                    }
                    sx={{ marginLeft: -2.3 }}
                  />
                )}
              </div>
            );
          })}
        </div>)} */}

        {/* 
        {isTeam && (
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Chip
              label="CREATE TEAM MEMBER"
              color="primary"
              icon={<AddIcon sx={{ fontSize: "16px" }} />}
              onClick={() => setShowCreateMemberDialog(true)}
              size="medium"
              sx={{
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: "0.8rem",
                width: 200
              }}
            />

            <Chip
              label={chipLabel}
              icon={<DeleteIcon sx={{ fontSize: 16 }} />}
              color="error"
              variant={chipVariant}
              size="medium"
              sx={{
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: "0.8rem"
              }}
              onClick={handleShowDelete}
            />
          </Box>
        )} */}



        {/* {showRemoveMembers && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" color="error" onClick={handleWantToRemove}>
              Remove Members
            </Button>
          </Box>
        )} */}

        <Box sx={{ flexGrow: 1 }} />

        {/* SEZIONE TEAM MEETINGS */}
        {/* SEZIONE TEAM MEETINGS */}
        {/* SEZIONE TEAM MEETINGS */}
        <Box sx={{ maxWidth: 600, position: 'relative', mb: 4 }}>
          <Typography sx={{ fontSize: 24, marginBottom: 2, color: 'gray' }}>
            TEAM MEETINGS
          </Typography>

          {teamMeetings.length === 0 ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ padding: '4px 12px' }}
              onClick={async () => {
                try {
                  // Creiamo la stanza
                  const res = await createRoom();
                  // Se la risposta contiene res.data.url
                  if (res?.data?.url) {
                    // Apri in una nuova tab
                    // window.open(res.data.url, '_blank');
                    setCallLink(res.data.url);
                    setInCall(true);
                  } else {
                    toast.error("Nessun link restituito dal server");
                  }
                } catch (error) {
                  toast.error("Errore nella creazione della call");
                }
              }}
            >
              CREATE MEETING
            </Button>
          ) : (<>
            <Box
              sx={{
                backgroundColor: '#052c65',
                color: 'white',
                padding: 2,
                borderRadius: 2,
                cursor: 'pointer',
                maxWidth: 'fit-content',
                ':hover': { opacity: 0.9 }
              }}
              onClick={() => {
                // if (callLink) {
                //   window.open(callLink, '_blank');
                // } else {
                //   toast.error("Nessun link disponibile per la call.");
                // }
                setInCall(true)
              }}
            >

              <Typography variant="h6" fontWeight="bold">
                JOIN MEETING
              </Typography>
              <Typography variant="body1">
                Click here to join the meeting!
              </Typography>
              {/* Bottone (Chip) rosso per terminare la riunione */}


            </Box>
            <Chip
              label="END MEETING"
              color="error"
              sx={{
                marginTop: 2,
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
              onClick={endMeeting}
            />

          </>

          )}


        </Box>



        {/* TEAM SETTINGS */}
        <Box sx={{ maxWidth: 600, position: 'relative' }}>
          <Typography sx={{ fontSize: 24, marginBottom: 2, color: 'gray' }}>
            TEAM SETTINGS
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: 16,
              marginBottom: 2
            }}
          >
            <Dialog
              open={showChangeName}
              onClose={() => setShowChangeName(false)}
              maxWidth="sm"
              fullWidth
            >
              <div className="change-name" data-palette-mode="dark">
                <div className="frame">
                  <div className="text-wrapper">CHANGE TEAM NAME</div>
                  <TextField
                    hasDiv={false}
                    defaultValue={team.name}
                    onChange={handleNameChange}
                    className="text-field-instance"
                    label="Name"
                    variant="filled"
                    size="medium"
                  />
                </div>
                <CheckIcon
                  className="icon-button-instance"
                  sx={{ color: 'white', cursor: 'pointer' }}
                  onClick={changeTeamName}
                  size="medium"
                />
              </div>
            </Dialog>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: 18, marginRight: 1 }}>
                Team Name:
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 100 }}>
                {isTeam && team.name?.startsWith('personal-') ? 'myTeam' : team.name}
              </Typography>
            </Box>

            {!showChangeName && isManager && (
              <Button variant="text" color="primary" onClick={handleEditTeamName}>
                EDIT
              </Button>
            )}
          </Box>
          {team.isOwner && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: 16
              }}
            >
              <Typography sx={{ fontSize: 18 }}>Delete team</Typography>
              <Button onClick={handleWantToDelete} variant="text" color="error">
                DELETE
              </Button>
            </Box>
          )}

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wantToDelete}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: '16px',
                padding: '20px',
                textAlign: 'center',
                maxWidth: '400px',
                margin: 'auto',
                width: '80%'
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 20, sm: 24 },
                  margin: "15px",
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                ARE YOU SURE?
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                  marginTop: '20px',
                  flexWrap: 'wrap',
                }}
              >
                <Chip
                  onClick={handleDeleteTeamClick}
                  sx={{
                    width: { xs: '120px', sm: '150px' },
                    borderRadius: '8px',
                    padding: { xs: '5px 10px', sm: '10px 20px' },
                    fontSize: { xs: '14px', sm: '16px' },
                    display: 'flex',
                    height: 65,
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  label={
                    <Typography sx={{ textAlign: 'center' }}>
                      DELETE <br /> TEAM
                    </Typography>
                  }
                  color='error'
                />
                <Chip
                  onClick={handleWantToDelete}
                  sx={{
                    width: { xs: '120px', sm: '150px' },
                    borderRadius: '8px',
                    height: 65,
                    padding: { xs: '5px 10px', sm: '10px 20px' },
                    fontSize: { xs: '14px', sm: '16px' },
                  }}
                  label="BACK"
                  color='info'
                />
              </div>
            </div>
          </Backdrop>

          {!team.isOwner && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingRight: 16
              }}
            >
              <Typography sx={{ fontSize: 18 }}>Abandon team</Typography>
              <Button variant="text" onClick={handleWantToAbandon} color="error">
                ABANDON TEAM
              </Button>
            </Box>
          )}

          <Dialog
            open={wantToAbandon}
            onClose={handleWantToAbandon}
            maxWidth="xs"
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: '16px',
                padding: '20px',
                textAlign: 'center',
                backgroundColor: 'white',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 20, sm: 24 },
                margin: "15px",
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              ARE YOU SURE?
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                marginTop: '20px',
                flexWrap: 'wrap',
              }}
            >
              <Chip
                onClick={handleWantToAbandonRemove}
                sx={{
                  width: { xs: '120px', sm: '150px' },
                  borderRadius: '8px',
                  padding: { xs: '5px 10px', sm: '10px 20px' },
                  fontSize: { xs: '14px', sm: '16px' },
                  display: 'flex',
                  height: 65,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                label={
                  <Typography sx={{ textAlign: 'center' }}>
                    ABANDON <br /> TEAM
                  </Typography>
                }
                color='error'
              />
              <Chip
                onClick={handleWantToAbandon}
                sx={{
                  width: { xs: '120px', sm: '150px' },
                  borderRadius: '8px',
                  height: 65,
                  padding: { xs: '5px 10px', sm: '10px 20px' },
                  fontSize: { xs: '14px', sm: '16px' },
                }}
                label="BACK"
                color='info'
              />
            </div>
          </Dialog>

        </Box>
        <Dialog
          open={showCreateMemberDialog}
          onClose={() => setShowCreateMemberDialog(false)}
          maxWidth="xs"
          fullWidth
          PaperProps={{
            sx: { borderRadius: '16px', padding: 2 }
          }}
        >
          <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            CREATE NEW MEMBER
          </DialogTitle>

          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
              <TextField
                label="Name"
                variant="filled"
                value={createName}
                onChange={(e) => setCreateName(e.target.value)}
              />
              <TextField
                label="Surname"
                variant="filled"
                value={createSurname}
                onChange={(e) => setCreateSurname(e.target.value)}
              />

            </Box>
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
            <Button
              onClick={handleCompleteAddMember}
              disabled={!isAllFilled}
              variant={isAllFilled ? "contained" : "outlined"}
            >
              CREATE
            </Button>
          </DialogActions>
        </Dialog>

        <Pricing open={openPrice} onClose={handlePricingClose} />
        <Dialog
          open={showUpgradeMemberDialog}
          onClose={() => setShowUpgradeMemberDialog(false)}
          maxWidth="xs"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '16px',
              padding: 3,
              textAlign: 'center'
            },
          }}
        >
          <DialogTitle sx={{ fontWeight: 'bold', fontSize: 20 }}>
            UPGRADE PLAN
          </DialogTitle>

          <DialogContent>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              Your monthly fee will go up by 10€.
            </Typography>
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setShowUpgradeMemberDialog(false)}
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpgradePlanExtra}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showDowngradeDialog}
          onClose={() => setShowDowngradeDialog(false)}
          maxWidth="xs"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: '16px',
              padding: 3,
              textAlign: 'center'
            },
          }}
        >
          <DialogTitle sx={{ fontWeight: 'bold', fontSize: 20 }}>
            DOWNGRADE PLAN
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              Your monthly fee will go down by 10€.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => setShowDowngradeDialog(false)}
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await handleDeleteSlots();
                setShowDowngradeDialog(false);
              }}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>


      </div>
    </>
  );
}

export default PageHeader;
