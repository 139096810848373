import { 
  Box, 
  Chip, 
  Typography, 
  IconButton, 
  Paper, 
  InputBase 
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import HttpProvider from 'services/HttpProvider';
import StepperContentLoader from './Loaders';
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";



const SelectRace = ({
  selectedTeam = {},
  activeRace = '',
  onChange = () => {},
  isTry,
  ...props
}) => {
  const competitions1 = [
    { id: 4, competition: "AiMotor Race", track: "Lonato" },
  ];

  const [competitions, setCompetitions] = useState(isTry ? competitions1 : []);
  const [isLoading, setIsLoading] = useState(false);
  const [race, setRace] = useState(activeRace);

  // Edit mode state
  const [editMode, setEditMode] = useState(false);

  // Search-related state
  const [searchTerm, setSearchTerm] = useState("");

  const history = useHistory();

  const fetchCompetitions = async () => {
    setIsLoading(true);
    HttpProvider.get(`/get_competitions/?team_id=${selectedTeam.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
    })
      .then((res) => {
        setCompetitions(res.data);
      })
      .catch((err) => {
        console.error("Error fetching competitions:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRaceClick = (id) => {
    setRace(id);
    onChange(id);
    setEditMode(false); // Exit edit mode when a race is selected
  };

  const handleInsertCompetition = () => {
    history.push("/racing-space/insert-competition");
  };

  const handleDeleteRace = (id) => {
    HttpProvider.delete("/remove_competition/", {
      params: { competition_id: id },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
    })
      .then(() => {
        setCompetitions((prev) => prev.filter((c) => c.id !== id));
        // toast.success("Race deleted successfully!", {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 3000,
        // });
      })
      .catch((err) => {
        console.error("Error deleting competition:", err);
        toast.error("Failed to delete the race. Please try again.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (!isTry) {
      fetchCompetitions();
    }
  }, [selectedTeam]);

  // Filter the competitions based on the search term
  const filteredCompetitions = competitions.filter((c) =>
    c.competition.toLowerCase().includes(searchTerm.toLowerCase()) ||
    c.track.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box textAlign="center" {...props}>
      {isLoading ? (
        <StepperContentLoader />
      ) : competitions.length > 0 ? (
        <>
          {/* Scrollable container for the list of chips */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              maxHeight: 400,
              overflowY: "auto",
              "@media (max-width: 600px)": {
                maxHeight: 250,
              },
            }}
          >
            {filteredCompetitions.map((c) => (
              <Chip
                label={`${c.competition} (${c.track})`}
                key={c.id}
                color={
                  race === ""
                    ? (c.id === filteredCompetitions[0]?.id ? "primary" : "default")
                    : (c.id === race ? "primary" : "default")
                }
                onClick={() => !editMode && handleRaceClick(c.id)}
                sx={{ mb: 1.5, mr: 2 }}
                icon={
                  editMode && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteRace(c.id);
                      }}
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </IconButton>
                  )
                }
              />
            ))}
          </Box>

          {/* Edit button + Search bar */}
          {!isTry && (
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                // Switch to column layout on small screens
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Chip
                label={editMode ? "Stop Editing" : "Edit"}
                onClick={() => setEditMode((prev) => !prev)}
                sx={{
                  fontSize: "0.9rem",
                  padding: "8px 16px",
                  backgroundColor: editMode ? "#e57373" : "#1976d2",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: editMode ? "#d32f2f" : "#115293",
                  },
                }}
              />

              {/* Always visible search bar */}
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 300,
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Competitions"
                  inputProps={{ "aria-label": "search competitions" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* Clear search button */}
                {searchTerm && (
                  <IconButton
                    sx={{ p: "10px" }}
                    aria-label="clear"
                    onClick={() => setSearchTerm("")}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Paper>
            </Box>
          )}
        </>
      ) : (
        // If no competitions
        <Box marginTop={3}>
          <Typography sx={{ mb: 2, fontSize: "1rem", color: "gray" }}>
            No competition inserted
          </Typography>
          <Chip
            label="Insert Competition"
            clickable
            onClick={handleInsertCompetition}
            sx={{
              fontSize: "1rem",
              padding: "12px 24px",
              backgroundColor: "#1976d2",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#115293",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default observer(SelectRace);
