import {
    Dialog, DialogContent, DialogTitle, Stepper, Step, StepLabel,
    Box, Button, Chip, TextField, Stack, Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TrackSearchDialog = ({ open, onClose, historicalTracks, onConfirm, initialStep = 0, searchTrack, searchLapTime  }) => {
    const [activeStep, setActiveStep] = useState(initialStep);
    const [selectedTrack, setSelectedTrack] = useState(searchTrack);
    const [lapTimeGoal, setLapTimeGoal] = useState(searchLapTime);
    const [error, setError] = useState(false);

   

// Rimuovi il precedente useEffect che inizializzava activeStep e aggiungi questo:
useEffect(() => {
    if (open) {
        setSelectedTrack(searchTrack);
        setLapTimeGoal(searchLapTime);
        // Se è già stata selezionata una track, imposta lo step a 1, altrimenti a 0
        setActiveStep(searchTrack ? 0 : 1);
    }
}, [open, searchTrack, searchLapTime]);

    const handleTrackSelect = (track) => {
        setSelectedTrack(track);
        
        setActiveStep(1); // Passa automaticamente al prossimo step
    };

    const handleLapTimeChange = (event) => {
        const inputValue = event.target.value;
        setLapTimeGoal(inputValue);
        // Resetta l'errore ad ogni modifica (l'errore verrà mostrato solo al click di Complete)
        setError(false);
    };

    const setUserTrackLaptime = async (track, laptime) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/set_user_track_laptime/`,
                null, // no body
                {
                    params: { track, laptime },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        accept: "application/json",
                    },
                }
            );
            console.log("Track and Laptime updated:", response.data);
        } catch (error) {
            console.error("Error updating track and laptime:", error);
        }
    };

    const handleConfirm = () => {
        // Accetta solo il formato "00:00:00": esattamente 2 cifre per minuti, secondi e centesimi, separate da :
        const regexColonFormat = /^\d{2}:\d{2}:\d{2}$/;

        if (lapTimeGoal && regexColonFormat.test(lapTimeGoal)) {
            onConfirm(selectedTrack, lapTimeGoal);
            setUserTrackLaptime(selectedTrack, lapTimeGoal)
            onClose(); // Chiude il dialog
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        setActiveStep(initialStep);
    }, [initialStep]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                {activeStep === 0 ? "Choose the Track" : "Choose Lap Time Goal"}
            </DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                        <StepLabel>Track</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Lap Time Goal</StepLabel>
                    </Step>
                </Stepper>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    {activeStep === 0 ? (
                        <Stack direction="row" gap={ 1}spacing={1} flexWrap="wrap" justifyContent="center">
                            {historicalTracks.map((track, index) => (
                                <Chip
                                    key={index}
                                    label={track}
                                    onClick={() => handleTrackSelect(track)}
                                    variant={selectedTrack === track ? "contained" : "outlined"}
                                    color={selectedTrack === track ? "primary" : "default"}
                                    sx={{ fontSize: '16px', padding: '10px', marginBottom: 3 }}
                                />
                            ))}
                        </Stack>
                    ) : (
                        <Box sx={{ textAlign: 'center', width: '100%' }}>
                            <TextField
                                fullWidth
                                label="Enter Lap Time Goal (es. 01:45:60)"
                                variant="outlined"
                                value={lapTimeGoal}
                                onChange={handleLapTimeChange}
                                sx={{
                                    input: { textAlign: 'center' }
                                }}
                            />
                            {error && (
                                <Typography color="red" sx={{ mt: 1, fontSize: '14px' }}>
                                    Invalid format. Use the format mm:ss:cent (01:45:60).
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>

                {activeStep === 1 && (
                    <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
                        <Button onClick={() => setActiveStep(0)}>Back</Button>
                        <Button onClick={handleConfirm} variant="contained" disabled={!lapTimeGoal}>
                            Complete
                        </Button>
                    </Stack>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TrackSearchDialog;
