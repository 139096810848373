import { Alert, Box, Button, Card, CardContent, Chip, FormControl, Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputLabel, LinearProgress, MenuItem, Modal, Select, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios from 'axios';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite";
import { Stack, styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { FileOutlined } from '@ant-design/icons';
import { Upload } from 'tus-js-client';
import { useHistory } from "react-router-dom";
import CryptoJS from 'crypto-js';
import Pricing from "components/pages/Pricing";
import { toast } from 'react-toastify';



function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Box>
  );
}

const FileIconButton = styled(IconButton)(({ theme, showError, isComplete }) => ({
  width: '150px',
  height: '150px',
  px: 1,
  border: `2px dashed ${showError ? theme.palette.error.main : isComplete ? theme.palette.success.main : theme.palette.error.main}`,
  borderRadius: 0,
}));

const FileCard = ({ file, onClose, onSave, onDelete, error, competitions, competitionLoading, setCompetitionLoading, race, showPersonalButton, setRace, handleChange, driver, lastSelectedDriver, emailName, drivers, setDriver }) => {
  const [sessionNumber, setSessionNumber] = useState(file?.sessionNumber || '');
  const [selectedSessionNumber, setSelectedSessionNumber] = useState(file?.sessionNumber || '');
  const [competition, setCompetition] = useState(file?.competition || '');
  const [selectOpen, setSelectOpen] = useState(false);
  const history = useHistory();
  const isOnBoard = file.file.name.split('.').pop().toLowerCase() !== 'csv'; // Verifica se l'estensione è mp4
  const selectedName = emailName.find((dataItem) => dataItem.email === driver);
  const name = selectedName ? selectedName.surname : '';
  const [raceError, setRaceError] = useState(false);
  const [driverError, setDriverError] = useState(false);
  const [sessionError, setSessionError] = useState(false);

  const handleSave = () => {
    let hasError = false;

    if (!race) {
      setRaceError(true);
      hasError = true;
    } else {
      setRaceError(false);
    }

    // Se non è il Personal Space, allora il driver è obbligatorio
    if (!showPersonalButton && (!driver || driver.length === 0)) {
      setDriverError(true);
      hasError = true;
    } else {
      setDriverError(false);
    }

    if (!sessionNumber) {
      setSessionError(true);
      hasError = true;
    } else {
      setSessionError(false);
    }

    if (hasError) {
      return;
    }

    onSave({ ...file, sessionNumber, race, driver });
  };

  const handleSessionClick = (sessionNumber) => {
    setSessionNumber(sessionNumber);
    setSelectedSessionNumber(sessionNumber);
  };

  const handleDoneClick = (event) => {
    event.stopPropagation();
    setSelectOpen(false);
  };
  const selectedTeam = myUserStore.getSelectedTeam;

  return (
    <Modal
      open={Boolean(file)}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return
        }
        onClose()
      }}
    >
      <Card
        sx={{
          width: '350px', // Ridotta leggermente rispetto a 400

          position: 'absolute', // Centrare assolutamente
          top: '50%', // Centrare verticalmente
          left: '50%', // Centrare orizzontalmente
          transform: 'translate(-50%, -50%)' // Sposta al centro
        }}
      >
        <CardContent>
          <div style={{ marginBottom: 20 }}>
            <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography variant="h6">
                <strong>{isOnBoard ? 'ON BOARD' : 'TELEMETRY FILE'}</strong>
              </Typography>
              <Chip
                variant="outlined"
                sx={{ marginLeft: 3 }}
                label={showPersonalButton ? 'Personal Space' : selectedTeam?.name}
              />
            </div>

            <Stack direction="row" spacing={1} alignItems={'flex-end'}>
              <Typography variant="h6" noWrap>
                {file.file.name}
              </Typography>
              <IconButton onClick={onDelete} color="error" sx={{ marginTop: -4 }}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          </div>
          <Box sx={{ display: 'flex', flexDirection: "column", gap: 3 }}>
            <Grid>
              <FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)' }} error={raceError}>
                <InputLabel id="race-select-label">RACE</InputLabel>
                <Select
                  labelId="race-select-label"
                  id="race-select"
                  value={race}
                  onChange={(e) => {
                    setRace(e.target.value);
                  }}
                  label="Race"
                >
                  {!competitionLoading ? (
                    competitions?.length === 0 ? (
                      <MenuItem value="">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => history.push('/racing-space/insert-competition')}
                          style={{ width: '100%' }}
                        >
                          Insert Competition
                        </Button>
                      </MenuItem>
                    ) : [
                      <MenuItem key="select-race" value="">Select Race</MenuItem>,
                      ...competitions?.map((competition) => (
                        <MenuItem key={competition.id} value={competition.id}>
                          {competition.competition} ({competition.track})
                        </MenuItem>
                      ))
                    ]
                  ) : (
                    <MenuItem value="" disabled>
                      <Skeleton variant="rectangular" sx={{ borderRadius: '8px', marginLeft: 0.5 }} height={30} />
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {!showPersonalButton && (
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <FormControl sx={{}} style={{ width: '100%' }} error={driverError}>
                  <InputLabel id="demo-multiple-chip-label">Driver</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    value={driver}
                    label="driver "
                    onChange={handleChange}
                    open={selectOpen}
                    onClose={() => setSelectOpen(false)}
                    onOpen={() => setSelectOpen(true)}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip key={name} label={name} />
                      </Box>
                    )}
                  >
                    {drivers.map((driver) => (
                      <MenuItem key={driver.id} value={driver.email}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          {driver.surname}
                          {lastSelectedDriver === driver.email && (
                            <Chip
                              label="done"
                              color="success"
                              size="small"
                              onClick={handleDoneClick}
                            />
                          )}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body1" color="black" sx={{ fontSize: '18px', fontWeight: 'lighter' }}>
                Session Number:
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {[1, 2, 3, 4, 5, 6].map((sessionNumber) => (
                  <Chip
                    sx={{
                      borderColor: sessionError && !selectedSessionNumber ? 'red' : 'inherit',
                      borderWidth: sessionError && !selectedSessionNumber ? 2 : 'auto',
                      borderStyle: sessionError && !selectedSessionNumber ? 'solid' : 'none',
                    }}
                    onClick={() => handleSessionClick(sessionNumber)} key={sessionNumber} label={sessionNumber} color="default" size="medium" variant={sessionNumber === selectedSessionNumber ? "contained" : "outlined"} />
                ))}
              </Box>
            </Grid>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Please insert all the file info
              </Alert>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, marginTop: 5 }}>
            <Button variant="outlined" onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSave}>Save</Button>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
};

const FileUpload = ({ handleSend = () => { }, handleStopUpload = () => { }, stopUpload }) => {
  const [fileGroups, setFileGroups] = useState([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState({ group: -1, file: -1 });
  const [files, setFiles] = useState([]);
  const [autoSubmitTriggered, setAutoSubmitTriggered] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  console.log("su 3", stopUpload)
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [race, setRace] = useState("");
  const [lastSelectedDriver, setLastSelectedDriver] = useState(null);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditingDirectly, setIsEditingDirectly] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const selectedTeam = myUserStore.getSelectedTeam;
  const isDriver = selectedTeam?.role === "DRV";


  const [uploadProgressTimer, setUploadProgressTimer] = useState(null); // Timer for checking upload status
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const myEmail = parsedUserData?.email;
  const uploadProgressInterval = useRef(null); // useRef to hold the interval ID
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [movError, setMovError] = useState(false);
  const [myPlan, setMyPlan] = useState(myUserStore?.subscriptionDetails?.custom_id);
  const showPersonalButton = myUserStore.isPersonalSpace;
  const [showPricing, setShowPricing] = useState(false);



  const handleSubmit = async () => {

    const hasCsvFile = fileGroups.flat().some(file => file.file.name.split('.').pop().toLowerCase() === 'csv');

    if (hasCsvFile) {
      submitFiles()
    } else {
      // Procedi direttamente con l'upload
      await submitFiles();
    }
  };

  useEffect(() => {
    if (
      !isSubmitting &&
      fileGroups.flat().length > 0 &&
      fileGroups.flat().every(file => isFileComplete(file)) &&
      !autoSubmitTriggered
    ) {
      setAutoSubmitTriggered(true);
      handleSubmit();
    }
  }, [fileGroups, isSubmitting, autoSubmitTriggered]);


  useEffect(() => {
    setCompetitionLoading(true);
    const fetchCompetitions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        const data = response.data;
        setCompetitions(data);
        setCompetitionLoading(false);

        if (data.length > 0) {
          setRace(data[data.length - 1].id);
        }
      } catch (error) {
        setCompetitionLoading(false);
        console.error("Error fetching competitions:", error);
      }
    };

    fetchCompetitions();
  }, [selectedTeam]);

  const handleChange = (event) => {
    const selectedDrivers = event.target.value;
    const { value } = event.target;
    if (value.length > 0) {
      setLastSelectedDriver(value[value.length - 1]);
      console.log("Driver selezionato:", value[value.length - 1]);
    } else {
      setLastSelectedDriver(null);
      console.log("Nessun driver selezionato");
    }
    setDriver(selectedDrivers);
    console.log("Nuovo stato di driver:", selectedDrivers);
  };


  useEffect(() => {
    console.log("useEffect - Caricamento dei driver");
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        console.log("Driver ricevuti dal server:", res.data);
        if (showPersonalButton) {
          setDrivers(res.data);
          setDriver([res.data[0].email]);
          console.log("Driver impostati per il personal space:", res.data[0].email);
        } else {
          setDrivers(res.data);
          if (isDriver) {
            const myDriver = res.data.find(item => item.email === myEmail);
            setDriver([myDriver?.email]);
            console.log("Driver impostati per il team:", myDriver?.email);
          }
        }
        setEmailName(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [selectedTeam, showPersonalButton, isDriver, myEmail]);


  const getUploadInfo = () => {

    axios

      .get(`${process.env.REACT_APP_API_DOMAIN}/files_uploaded`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {

        console.log("percentage", res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    debugger
    const filteredAcceptedFiles = acceptedFiles.filter(file => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      debugger
      if (fileExtension !== 'csv' && myPlan === 'Growth') {
        setShowPricing(true);
        return false; // Il file non viene considerato
      }
      return true;
    });
    for (const file of filteredAcceptedFiles) {
      const fileExtension = file.name.split('.').pop();
      if (fileExtension === 'MOV') {
        setMovError(true);
        return; // Interrompe l'elaborazione
      }
    }

    console.log("onDrop - File accettati:", filteredAcceptedFiles);
    console.log("onDrop - File rifiutati:", rejectedFiles);
    // Filtriamo i file che NON siano né CSV né MP4
    const invalidFiles = filteredAcceptedFiles.filter(
      (file) => !['text/csv', 'video/mp4', 'video/mov', 'video/quicktime', 'video/mkv', 'video/avi', 'video/flv', 'video/webm', 'video/3gp'].includes(file.type)
    );
    // Se c'è almeno un file non valido, mostriamo errore e usciamo
    if (invalidFiles.length > 0 || rejectedFiles.length > 0) {
      setErrorMessage('Only CSV or Video files are allowed. Please upload a CSV or Video file.');
      console.log("Errore: formato file errato");
      return;
    }
    const videoMimeTypes = [
      'video/mp4',
      'video/mov',
      'video/quicktime',
      'video/mkv',
      'video/avi',
      'video/flv',
      'video/webm',
      'video/3gp',
    ];

    // Filtriamo i CSV
    const csvFiles = filteredAcceptedFiles.filter(file => file.type === 'text/csv');
    // Filtriamo gli MP4
    const videoFiles = filteredAcceptedFiles.filter(file => videoMimeTypes.includes(file.type));

    // Creiamo un array di oggetti per entrambi i tipi
    const newFiles = [
      ...csvFiles.map(file => ({ file, sessionNumber: '', race: '', driver: '' })),
      ...videoFiles.map(file => ({ file, sessionNumber: '', race: '', driver: '' }))
    ];

    if (newFiles.length > 0) {
      setFileGroups((prevGroups) => [...prevGroups, newFiles]);
      setSelectedFileIndex({ group: fileGroups.length, file: 0 });
      setRace('');
      if (!showPersonalButton) {
        setDriver([]);
        console.log("Driver resettato dopo l'aggiunta di nuovi file");
      }
      setLastSelectedDriver(null);
    }


    setErrorMessage('');
  };



  const stopUploadRef = useRef(stopUpload);
  useEffect(() => {
    stopUploadRef.current = stopUpload;
  }, [stopUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'video/mp4': ['.mp4'],
      'video/mov': ['.mov'], // Aggiungi MOV
      'video/mkv': ['.mkv'], // Aggiungi MKV
      'video/avi': ['.avi'], // Aggiungi AVI
      'video/flv': ['.flv'], // Aggiungi FLV
      'video/webm': ['.webm'], // Aggiungi WebM
      'video/3gp': ['.3gp'], // Aggiungi 3GP
    }
  });

  const handleModalClose = () => {
    setSelectedFileIndex({ group: -1, file: -1 });
    setIsEditingDirectly(false);
  };

  const handleFileClick = (groupIndex, fileIndex) => {
    setSelectedFileIndex({ group: groupIndex, file: fileIndex });
    setIsEditingDirectly(true);
    const file = fileGroups[groupIndex][fileIndex];
    setRace(file.race || '');
    setDriver(file.driver || []);
    setLastSelectedDriver(file.driver?.length ? file.driver[file.driver.length - 1] : null);
  };

  const handleFileSave = (fileDetails) => {
    console.log("handleFileSave - Salvataggio file con dettagli:", fileDetails);
    console.log("Driver prima del salvataggio:", driver);
    const { group, file } = selectedFileIndex;
    const updatedFiles = fileGroups.map((groupFiles, groupIndex) =>
      groupIndex === group
        ? groupFiles.map((f, fileIndex) =>
          fileIndex === file ? { ...fileDetails, file: new File([f.file], fileDetails.file.name) } : f
        )
        : groupFiles
    );
    setFileGroups(updatedFiles);

    if (isEditingDirectly) {
      handleModalClose();
    } else {
      if (file < fileGroups[group].length - 1) {
        setSelectedFileIndex({ group, file: file + 1 });
        setRace('');
        if (!showPersonalButton) {
          setDriver([]);
          console.log("Driver resettato perché non è nel personal space");
        }
        setLastSelectedDriver(null);
        console.log("LastSelectedDriver resettato");
      } else {
        setSelectedFileIndex({ group: -1, file: -1 });
      }
    }
    console.log("handleFileSave - Fine");
  };



  const handleFileDelete = () => {
    const { group, file } = selectedFileIndex;
    const updatedFiles = fileGroups.map((groupFiles, groupIndex) =>
      groupIndex === group ? groupFiles.filter((_, fileIndex) => fileIndex !== file) : groupFiles
    );
    setFileGroups(updatedFiles);
    setSelectedFileIndex({ group: -1, file: -1 });
    setIsEditingDirectly(false);
  };

  const generateSignature = async (libraryId, apiKey, expirationTime, videoId) => {
    const data = `${libraryId}${apiKey}${expirationTime}${videoId}`;

    // Calcolo hash con crypto-js
    const hash = CryptoJS.SHA256(data);

    // Converte l’oggetto hash in stringa esadecimale
    const signature = hash.toString(CryptoJS.enc.Hex);

    // Alcuni log di esempio (rimuovili in produzione)
    console.log("Data for hashing:", data);
    console.log("Generated signature:", signature);

    return signature;
  };

  // Funzione per creare un nuovo caricamento con tus.js
  const callUploadVideo = async (videoFile) => {
    console.log("Inizio caricamento video", videoFile);
    const libraryId = 361126; // ID della libreria
    const videoId = await createVideoMetadata(videoFile); // Crea i metadati e ottieni il videoId
    console.log("Video ID creato con successo", videoId);

    const expirationTime = Math.floor(Date.now() / 1000) + 3600; // Valida per 1 ora
    const apiKey = "83d006c0-f495-4a2f-b2937024639c-b620-4a18"; // Chiave API sicura
    const signature = await generateSignature(libraryId, apiKey, expirationTime, videoId);
    try {
      // Genera la firma

      console.log("Parametri di upload preparati", { expirationTime, signature });

      const url = "https://video.bunnycdn.com/tusupload";

      // Wrappiamo l'upload in una Promise
      return new Promise((resolve, reject) => {
        const upload = new Upload(videoFile.file, {
          endpoint: url,
          retryDelays: [0, 3000, 5000, 10000],
          headers: {
            AuthorizationSignature: signature,
            AuthorizationExpire: expirationTime,
            VideoId: videoId,
            LibraryId: libraryId,
          },
          metadata: {
            filetype: videoFile.file.type,
            title: videoFile.file.name,
            collection: "collectionID", // Opzionale, usa se necessario
          },
          onError: function (error) {
            console.error("Errore durante l'upload", error);
            if (error.message.includes("Upload aborted")) {
              console.log(`Upload annullato per il file: ${videoFile.file.name}`);
              resolve();
              return;
            }
            reject(error);
          },
          onError: function (error) {
            console.error("Errore durante l'upload", error);
            setFileGroups([]);
            setSelectedFileIndex([]);
            clearInterval(uploadProgressInterval.current);

            // Aggiorniamo lo stato con i dettagli dell'errore
            handleSend((prevUploads) => {
              const updated = prevUploads.map((file) => {
                if (file.name === videoFile.file.name) {
                  return {
                    ...file,
                    mega: 'error',        // o 'percentage': 'error' se usi un'altra chiave
                    errorDetails: error.message,
                  };
                }
                return file;
              });
              return updated;
            });

            if (error.message.includes("Upload aborted")) {
              console.log(`Upload annullato per il file: ${videoFile.file.name}`);
              resolve();
              return;
            }
            reject(error);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            console.log("Progresso upload aggiornato", { bytesUploaded, bytesTotal });

            const shouldStop = stopUploadRef?.current?.some(
              (stopObj) => stopObj.name === videoFile?.file?.name && stopObj.stop === true
            );

            if (stopUploadRef?.current?.length > 0) {
              debugger
              upload.abort();
              console.log(`Upload interrotto per il file: ${videoFile.file.name}`);
              reject(new Error(`Upload aborted for file: ${videoFile.file.name}`));
              setFileGroups([]);
              setSelectedFileIndex([]);
              clearInterval(uploadProgressInterval.current);
              return;
            }

            const progressPercentage = Math.round((bytesUploaded / bytesTotal) * 100);
            const loadedMega = (bytesUploaded / (1024 * 1024)).toFixed(2);
            const totalMega = (bytesTotal / (1024 * 1024)).toFixed(2);
            const remainingMega = totalMega - loadedMega;
            // @denis speed needs to be created
            // const elapsedTime = (Date.now() - startTime) / 1000; // in secondi
            // const speedBps = bytesUploaded / elapsedTime;
            // const speedMbs = (speedBps / 1048576).toFixed(2); // in MB/s, limitato a 2 decimali

            const uploadInfo = {
              name: videoFile.file.name,
              progress: progressPercentage,
              size: videoFile.file.size,
              mega: loadedMega,
              // speed: speedMbs
            };

            console.log("Informazioni upload", uploadInfo);

            handleSend((prevUploads) => {
              const updatedUploads = prevUploads.map((file) =>
                file.name === uploadInfo.name
                  ? { ...file, progress: uploadInfo.progress, mega: uploadInfo.mega, remainingTime: uploadInfo.remainingTime }
                  : file
              );
              return updatedUploads;
            });
          },

          onSuccess: function () {
            console.log("Upload completato con successo per il video", videoId);
            resolve(videoId);
            uploadVideoMetadata(videoFile, videoId);
          },
        });

        // Cerca caricamenti precedenti e riprendi, se esistono
        // upload.findPreviousUploads().then((previousUploads) => {
        //   if (previousUploads.length) {
        //     console.log("Ripresa caricamenti precedenti", previousUploads);
        //     upload.resumeFromPreviousUpload(previousUploads[0]);
        //   }

        //   // Avvia l'upload

        // });
        console.log("Avvio upload");
        upload.start();
      });
    } catch (error) {
      console.error("Errore durante il caricamento del video", error);
      throw error;
    }
  };




  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  // try {
  //   const response = await axios.post(
  //     `${process.env.REACT_APP_API_DOMAIN}/upload_video`,
  //     formData,
  //     {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         'Authorization': `Bearer ${token}`,
  //         'filename': videoFile.file.name,
  //         'session': videoFile.sessionNumber,
  //         'race': videoFile.race ,
  //         'teamid': selectedTeam.id,
  //         'driver': Array.isArray(videoFile.driver) ? videoFile.driver[0] : videoFile.driver,

  //       },
  //       onUploadProgress: progressEvent => console.log("Progress event:", progressEvent.loaded, "of", progressEvent.total),

  //     }
  //   );
  //   return response.data;
  // } catch (error) {
  //   console.error('Errore durante il caricamento del video:', error);
  //   throw error;
  // }

  const getCollection = async () => {
    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/create_collection`;

      const response = await axios.post(
        url,
        {}, // Corpo della richiesta con il titolo del video
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      console.log('Video creato con successo:', response.data);
      return response.data.collection_id; // Restituisce i dettagli del video creato
    } catch (error) {
      console.error('Errore durante la creazione del video:', error.response?.data || error.message);
      throw error;
    }
  };

  const createVideoMetadata = async (videoFile) => {
    const collectionId = await getCollection();
    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/create_video?collection_id=${collectionId}&video_title=${videoFile.file.name}`;

      const response = await axios.post(
        url,
        {}, // Corpo della richiesta con il titolo del video
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      console.log('Video creato con successo:', response.data);
      return response.data.guid; // Restituisce i dettagli del video creato
    } catch (error) {
      console.error('Errore durante la creazione del video:', error.response?.data || error.message);
      throw error;
    }
  };

  const uploadVideoMetadata = async (f, videoId) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/upload_video_metadata`,
        null, // Nessun body diretto, i dati sono passati come query parameters
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            filename: f.file.name,
            session: f.sessionNumber,
            race: f.race,
            video_id: videoId,
            driver: Array.isArray(f.driver) ? f.driver[0] : f.driver,
            team_id: selectedTeam.id,
            file_size: f.file.size,
          },
        }
      );
      console.log('Upload video metadata successful:', response.data);
      return response.data; // Restituisce l'ID di inserimento
    } catch (error) {
      const { detail } = error.response.data
      console.error('Error uploading video metadata:', detail || error.message);
      toast.error(detail || "Something went wrong", {
        position: "top-right",
        autoClose: 2000, // Close after 3 seconds
        hideProgressBar: false,
      });
      return error
    }
  };



  const submitFiles = async () => {
    const filesToUpload = fileGroups.flat().map((file) => ({
      name: file.file.name,
      size: file.file.size,
      mega: 0,
      progress: 0, // Initial progress
    }));
    handleSend(filesToUpload);

    console.log("handleSubmit - Inizio");
    console.log("File Groups:", JSON.stringify(fileGroups, null, 2));
    console.log("Driver State:", driver);
    console.log("Race:", race);
    console.log("Selected Team:", selectedTeam);

    // Controllo dei file incompleti
    const incompleteFiles = fileGroups.flat().filter(file => !file?.sessionNumber || !file.race || !file.driver || file.driver.length === 0);
    if (incompleteFiles.length > 0) {
      console.log("handleSubmit - File incompleti trovati");
      setShowError(true);
      const firstIncompleteIndex = fileGroups.flat().indexOf(incompleteFiles[0]);
      setSelectedFileIndex({
        group: Math.floor(firstIncompleteIndex / fileGroups[0].length),
        file: firstIncompleteIndex % fileGroups[0].length,
      });
      return;
    }

    // Resettare gli errori e impostare lo stato di caricamento
    setShowError(false);
    setIsSubmitting(true);
    setSuccessMessage('');
    setProgressVisible(true);

    const token = localStorage.getItem('access_token');
    let allFilesUploaded = false;

    try {
      // Ottieni informazioni sull'upload (opzionale, a seconda della tua logica)
      await getUploadInfo();

      // Itera su tutti i gruppi di file e carica ciascun file
      for (const group of fileGroups) {
        for (const file of group) {
          console.log("Preparando l'upload per il file:", file.file.name);
          console.log("Race:", file.race);
          console.log("Session Number:", file.sessionNumber);
          console.log("Driver:", file.driver);

          // Verifica che il driver non sia vuoto
          if (!file.driver || file.driver.length === 0) {
            console.error(`Driver vuoto per il file: ${file.file.name}`);
            throw new Error(`Driver vuoto per il file: ${file.file.name}`);
          }

          const extension = file.file.name.split('.').pop().toLowerCase();
          if (!(extension === 'csv')) {
            // ------- UPLOAD VIDEO -------
            console.log(`Caricamento di un video MP4: ${file.file.name}`);
            await callUploadVideo(file);
            console.log(`Upload video riuscito per: ${file.file.name}`);
          } else {
            // ------- UPLOAD CSV (o altro) -------
            console.log(`Caricamento di un file CSV: ${file.file.name}`);
            const formData = new FormData();
            formData.append('file', file.file);

            const queryParams = new URLSearchParams();
            queryParams.append('race', file.race);
            queryParams.append('session', file?.sessionNumber);
            // Assicurati di passare un singolo email se 'driver' è un array
            queryParams.append('email', Array.isArray(file.driver) ? file.driver[0] : file.driver);
            queryParams.append('team_id', selectedTeam.id);

            console.log("URL di upload CSV:", `${process.env.REACT_APP_API_DOMAIN}/upload/?${queryParams.toString()}`);
            const controller = new AbortController();
            const response = await axios.post(
              `${process.env.REACT_APP_API_DOMAIN}/upload/?${queryParams.toString()}`,
              formData,

              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`,
                  'accept': 'application/json',
                },
                signal: controller.signal,
                onUploadProgress: (progressEvent) => {
                  const shouldStop = stopUploadRef?.current?.some(
                    (stopObj) => stopObj.name === file?.file?.name && stopObj.stop === true
                  );
                  if (shouldStop) {
                    controller.abort();
                    console.log(`Upload interrotto per il file: ${file.name}`);
                    console.log(`Upload interrotto per il file: ${file.file.name}`);

                    setFileGroups([]);
                    setSelectedFileIndex([]);
                    clearInterval(uploadProgressInterval.current);
                    return;
                  }

                  const progressPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                  const loadedMega = (progressEvent.loaded / (1024 * 1024)).toFixed(2); // Converti in MB

                  const uploadInfo = {
                    name: file.file.name,
                    progress: progressPercentage,
                    size: file.file.size,
                    mega: loadedMega,
                  };

                  handleSend((prevUploads) => {
                    const updatedUploads = prevUploads.map((file) =>
                      file.name === uploadInfo.name ? { ...file, progress: uploadInfo.progress, mega: uploadInfo.mega } : file
                    );
                    return updatedUploads;
                  });// Invia l'oggetto di stato aggiornato

                  console.log(
                    'Progresso upload:',
                    `${progressEvent.loaded} byte caricati su ${progressEvent.total}`,
                    `(${progressPercentage}%, ${loadedMega} MB)`
                  );
                },

              }

            );

            if (response.status === 200) {
              setUploadStatus((prevStatus) => ({
                ...prevStatus,
                [file.file.name]: 'success',
              }));
              console.log(`Upload CSV riuscito per il file: ${file.file.name}`);
            } else {
              console.log(`Upload CSV fallito per il file: ${file.file.name}`);
              throw new Error(`Upload CSV fallito per il file: ${file.file.name}`);
            }
          }
        }

      }

      // Tutti i file sono stati caricati con successo
      setSuccessMessage('Files uploaded successfully!');
      setFileGroups([]);

      allFilesUploaded = true;

      if (allFilesUploaded) {
        clearInterval(uploadProgressInterval.current); // Pulisci l'intervallo se usato
        uploadProgressInterval.current = null;
        handleSend([], true)// Resetta il riferimento
      }
    } catch (error) {
      console.error('Error uploading files', error);

      if (stopUpload?.length > 0) {
        alert('Error uploading files');

      } else {
        handleStopUpload([])
      }

    } finally {
      setIsSubmitting(false);
      setProgressVisible(false);
      setAutoSubmitTriggered(false);
    }
  };



  const isFileComplete = (file) => file?.sessionNumber && file.race && file.driver;

  return (
    <Box>
      <Pricing open={showPricing} onClose={() => setShowPricing(false)} />
      <Dialog
        open={movError}
        onClose={() => setMovError(false)}
        PaperProps={{
          sx: {
            padding: 4,        // per rendere il contenuto più "aperto"
            borderRadius: 4,   // per averlo più “quadrato” (o 2 se lo preferisci meno arrotondato)
            width: '400px',    // larghezza fissa
            maxWidth: '90%'    // limita la larghezza su schermi piccoli
          }
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}>
          Unsupported File
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" paragraph>
            Files with extension <strong>.MOV</strong> are not supported.
            Please rename or convert them to <strong>.mov</strong>.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setMovError(false)}
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={false} onClose={closeDialog} PaperProps={{ sx: { padding: 5, width: '400px', maxWidth: '90%', borderRadius: '16px' } }}>
        <DialogTitle sx={{ textAlign: 'center', color: 'blue', fontWeight: 'bold', fontSize: '1.8rem' }}>
          IMPORTANT
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', marginBottom: 3 }}>
            The <strong>Telemetry files</strong> must be related to the <strong>same track</strong> as the races chosen.
            Please ensure this requirement is met before proceeding.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: 2 }}>
          <Chip variant="outlined" label={'Go Back'} onClick={closeDialog} color="error" sx={{ fontWeight: 'bold' }} />


          <Chip
            variant="contained"
            label={'  Continue'}
            onClick={async () => {
              closeDialog();
              await submitFiles(); // Funzione per eseguire l'upload
            }}
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >

          </Chip>
        </DialogActions>
      </Dialog>

      <Box {...getRootProps()} sx={{ border: '2px dashed', padding: 2, textAlign: 'center', cursor: 'pointer', height: 350, alignContent: 'center', flexDirection: 'column', alignItems: 'center', paddingTop: '50px' }}>
        <input {...getInputProps()} />
        <Typography>Drag and drop one or more files here, or click to select the files</Typography>
        <Typography mb={2}>
          You can upload <strong>Telemetry files</strong> and <strong>On Board videos</strong>
        </Typography>
        <Button variant="contained" component="span">Select Files</Button>
      </Box>
      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Box display={"flex"} flexWrap={"wrap"} mt={2}>
        {fileGroups.map((group, groupIndex) =>
          Array.isArray(group) && group.map((file, fileIndex) => (
            <Box sx={{ display: 'flex', mr: 4, flexWrap: 'wrap', mt: 2 }}>
              <FileIconButton
                key={`${groupIndex}-${fileIndex}`}
                component={Stack}
                spacing={2}

                onClick={() => handleFileClick(groupIndex, fileIndex)}
                showError={!isFileComplete(file)}
                isComplete={isFileComplete(file)}
              >
                {file.file.name.split('.').pop().toLowerCase() === 'mp4' ? (
                  <OndemandVideoIcon fontSize="large" /> // Cambia con l'icona di un video con Play
                ) : (
                  <UploadFileIcon fontSize="large" />
                )}
                <Typography variant='body1' sx={{ width: '100%' }}>
                  {file.file.name.length > 15 ? `${file.file.name.slice(0, 15)}...` : file.file.name}
                </Typography>
                {uploadStatus[file.file.name] === 'success' && (
                  <CheckCircleIcon sx={{ color: 'green', position: 'absolute', top: 5, right: 5 }} />
                )}
              </FileIconButton>
            </Box>
          ))
        )}
      </Box>
      {selectedFileIndex.group >= 0 && selectedFileIndex.file >= 0 && (
        <FileCard
          file={fileGroups[selectedFileIndex.group][selectedFileIndex.file]}
          onClose={handleModalClose}
          onSave={handleFileSave}
          onDelete={handleFileDelete}
          error={showError && !isFileComplete(fileGroups[selectedFileIndex.group][selectedFileIndex.file])}
          competitions={competitions}
          competitionLoading={competitionLoading}
          setCompetitionLoading={setCompetitionLoading}
          race={race}
          setRace={setRace}
          handleChange={handleChange}
          driver={driver}
          lastSelectedDriver={lastSelectedDriver}
          emailName={emailName}
          drivers={drivers}
          setDriver={setDriver}
          showPersonalButton={showPersonalButton}
        />
      )}
      {fileGroups.flat().length > 0 && (
        fileGroups.flat().length > 5 ? (
          <Chip
            label="You can upload up to 5 video at a time"
            variant="outlined"
            color="error"
            sx={{ mt: 5 }}
          />
        ) : (
          !isSubmitting ? (
            // <Button
            //   variant="contained"
            //   onClick={handleSubmit}
            //   sx={{ mt: 5 }}
            //   disabled={isSubmitting}
            // >
            //   Submit
            // </Button>
            <div></div>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{ mt: 2, color: 'gray', fontWeight: 'bold', textAlign: 'center' }}
              >
                Uploading files...
              </Typography>
              <Typography
                variant="body2"
                sx={{ mt: 1, color: 'black', textAlign: 'center' }}
              >
                Please don't close AiMotor
              </Typography>
            </>
          )
        )
      )}

      {successMessage && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {successMessage}
        </Alert>
      )}
    </Box>
  );
};

export default observer(FileUpload);




