import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { Link } from "react-router-dom";
import { keyframes } from '@emotion/react';

// Sample text array to cycle through
const textArray = [
  "I am experiencing understeer. Should I increase the camber angle to correct it?",
  "Can you help me understand why my teammate is achieving faster lap times than I am?",
  "I'm unsure if changing the sprocket is the right decision for qualifying. Considering my racing data from the previous day, what do you suggest?"
];

// Keyframes for the text fade-in and fade-out animation
const fade = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

// Custom theme for styling
const theme = createTheme({
  palette: {
    primary: {
      main: '#5865f2',
    },
    background: {
      default: '#0a0c19',
      paper: '#000',
    },
    text: {
      primary: '#e4dff7',
      secondary: '#bdbdbd',
    },
  },
  typography: {
    fontSize: 18,
    h1: {
      fontSize: '2rem', // Updated font size
      animation: `${fade} 6s infinite`,
      color: 'rgba(210, 146, 255, 1)', // Updated color
      fontWeight: 'bold',
      textAlign: 'center',
    },
    h2: {
      fontSize: '1.5rem',
      color: '#fff',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
  },
});

const GetStarted = () => {
  const [index, setIndex] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 6000); // Change text every 6 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
    // </ThemeProvider>
    <Box
      display="flex"
      height="100vh"
      backgroundColor="#000"
      color="#e4dff7"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      {!isMobile && (
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={4}
          bgcolor="#1a1a2e" // Change this to match the left side color
        >
          <Typography
            variant="h3"
            textAlign={'center'}
            sx={{
              animation: `${fade} 6s infinite`,
              color: 'rgba(210, 146, 255, 1)',
              fontWeight: 700
            }}
          >
            {textArray[index]}
          </Typography>
        </Box>
      )}
      <Box
  flex={1}
  bgcolor="#000"
  display="flex"
  alignItems="center"
  justifyContent="center"
  flexDirection="column"
  width={isMobile ? '100%' : 'auto'}
>
  <Typography variant="h4" color="white">
    Get Started
  </Typography>
  <Stack direction="row" spacing={2} mt={2}>
    <Link to="/sign-in" className="insertdata">
      <Button
        variant="contained"
        color="secondary"
        size="large"
      >
        Log in
      </Button>
    </Link>
    <Link to="/sign-up" className="insertdata">
      <Button
        variant="outlined"
        color="light"
        size="large"
      >
        Sign up
      </Button>
    </Link>
  </Stack>
  {/* Aggiunto il pulsante Try Demo */}
  {/* <Link to="/racing-space-try" className="insertdata">
  <Button 
    variant="contained" 
    style={{
      background: 'linear-gradient(90deg, #3A49F9, #A038F5)',
      color: "white",
      marginTop: '20px', // Spazio per separarlo dagli altri pulsanti
    }}
    onClick={() => console.log("Try Demo clicked")}
  >
    Try Demo
  </Button>
  </Link> */}
</Box>

    </Box>
  );
};

export default GetStarted;
