import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import { useState, useEffect, useMemo } from 'react';
import 'leaflet/dist/leaflet.css';
// import latlong from './TryFiles/latlong_chat'; // Se non lo usi, puoi rimuoverlo
import { IconButton, Chip, Typography, Box } from '@mui/material';
import { ArrowUpward, ArrowDownward, ArrowForward, ArrowBack } from '@mui/icons-material';
import L from 'leaflet';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import { useHistory } from 'react-router-dom';
import DistributionDialog from './DistributionDialog';
import myUserStore from '../../stores/UserStore';
import Draggable from 'react-draggable';
import { brown } from '@mui/material/colors';

const TrackMapEarth = ({ searchTrack,isCustom, searchLapTime, handleColorDist = () => { }, distChange, localShowSectors, notSearch, differentTrack = '', isRecap, showSectors, colorVector = [], isAnalytics, sectorAnalytics, driversData = [], center, trackIndex, revealButtons }) => {
  // -------------------
  // STATE

  // -------------------
  const [trajectory, setTrajectory] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sectorPoints, setSectorPoints] = useState([]);
console.log ("isCustom", isCustom)
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [noData, setNoData] = useState(false);
  const showPersonalButton = myUserStore.isPersonalSpace;
  const user = JSON.parse(localStorage.getItem("user_data") || "{}");
  const selectedTeam = myUserStore.getSelectedTeam;
  const [redPoints, setRedPoints] = useState([]);
  const history = useHistory();

  const [greenPoints, setGreenPoints] = useState([]);
  const [finish, setFinish] = useState([]);
  const [distTrajectories, setDistTrajectories] = useState([]);
  const [distData, setDistData] = useState([]);
  // Stato per le traiettorie in modalità analytics
  const [analyticsTrajectories, setAnalyticsTrajectories] = useState([]);

  console.log('diff track ', differentTrack)
  // -------------------
  // FUNZIONI DI FETCH
  // -------------------
  const getCentroids = async (givenTrack) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/centroids_data/?track=${givenTrack}`);
      const data = response.data.slice(0)
      //const data = response.data.slice(1)

      setFinish(response.data[0])
      setSectorPoints(data);
    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  };
  console.log("finish")
  const navigateToTelemetryData = () => {
    history.push('/racing-space/telemetry-data');
  };

  const getTrackUser = async () => {
    if (isRecap) {
      getCentroids(differentTrack);
      getLongLatTrack(differentTrack);
    }
    setNoData(false);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/latest_track_user/?driver=${user.email}`);
      const data = differentTrack ? differentTrack : response.data;
      getCentroids(differentTrack ? differentTrack : data.latest_track);
      getLongLatTrack(differentTrack ? differentTrack : data.latest_track);
    } catch (error) {
      console.error("Error fetching competitions:", error);
      setNoData(true)
    }
  };

  const getTrackTeam = async () => {
    if (isRecap) {
      getCentroids(differentTrack);
      getLongLatTrack(differentTrack);
    } setNoData(false)
    try {

      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/latest_track_team/?team_id=${selectedTeam.id}`);
      const data = differentTrack ? differentTrack : response.data;
      getCentroids(differentTrack ? differentTrack : data.latest_track);
      getLongLatTrack(differentTrack ? differentTrack : data.latest_track);
    } catch (error) {
      console.error("Error fetching competitions:", error);
      setNoData(true)
    }
  };
  const getDistLines = (race, driverSessions) => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/get_filtered_longitude_latitude?race=${race}`,
        driverSessions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((userResponse) => {
        // setIsLoading(false);
        // const firstMultiTrajectory = userResponse.data[0].drivingData.map(pt => ({
        //   lat: pt.latitude,
        //   lon: pt.longitude
        // }));

        // const center1 = [
        //   firstMultiTrajectory[0].lat,
        //   firstMultiTrajectory[0].lon
        // ];
        // setCenter ( center1)
        // setDataset(userResponse.data);
        // getSectors();
      })
      .catch((error) => {
        console.error("Query Error:", error);
        setIsLoading(false);
      });
  };
  const getLongLatTrack = async (givenTrack) => {
    const url = isRecap
      ? `${process.env.REACT_APP_API_DOMAIN}/get_track_gps/?track=${givenTrack}`
      : `${process.env.REACT_APP_API_DOMAIN}/long_lat_for_track/?track=${givenTrack}`;

    setIsLoading(true)
    try {
      const response = await axios.get(`${url}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      const data = response.data;
      setIsLoading(false)
      if (!isRecap) {
        const filteredData = data
          .filter((_, index) => index % 4 === 0)
          .map(item => ({ lon: item?.longitude, lat: item?.latitude }));
        setTrajectory(filteredData);
        console.log("Filtered Track Data:", filteredData);
      } else {
        if (data && data?.lat != null && data?.lon != null) {
          setTrajectory([{ lat: data?.lat, lon: data?.lon }]);
        } else {
          setTrajectory([]);
        }
      }


    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching competitions:", error);
    }
  };
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const fetchGivenLaps = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/laps_telemetry_highlights_user?team_id=${selectedTeam.id}&user_email=${parsedUserData.email}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        // Se laps telemetry ritorna un array vuoto, usa il nuovo endpoint
        debugger
        if (Array.isArray(res.data) && isCustom) {
          axios
            .get(
              `${process.env.REACT_APP_API_DOMAIN}/get_data_distribution/?team_id=${selectedTeam.id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                  accept: "application/json",
                },
              }
            )
            .then((distRes) => {
          
              setDistData(Array.isArray(distRes.data?.custom_distribution) ? distRes.data?.custom_distribution : []);
              if (Array.isArray(distRes.data?.custom_distribution)) {
                const requests = distRes.data?.custom_distribution?.map((highlight) => {
                  const driverSessions = [
                    {
                      email: highlight.driver,
                      sessions: [
                        {
                          session: highlight.session,
                          laps: [highlight.lap],
                        },
                      ],
                    },
                  ];
                  return axios.post(
                    `${process.env.REACT_APP_API_DOMAIN}/get_filtered_longitude_latitude?race=${highlight.race}`,
                    driverSessions,
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  );
                });
                Promise.all(requests)
                  .then((responses) => {
                    // Check if any response has an empty array (or is missing data)
                    const anyEmpty = responses.some(
                      (resp) => !Array.isArray(resp.data) || resp.data.length === 0
                    );

                    if (anyEmpty) {
                      // If even one is empty, setDistTrajectories to []
                      setDistTrajectories([]);
                      return;
                    }

                    // Otherwise, build newDistTrajectories as usual
                    const newDistTrajectories = responses.map((resp, idx) => ({
                      highlight: res.data[idx],
                      latLongData: resp.data[0],
                    }));

                    setDistTrajectories(newDistTrajectories);
                  })
                  .catch((error) => {
                    console.error(error);
                    setDistTrajectories([]); // or your preferred error handling
                  });

              }
            })
            .catch((error) => {
              console.error("Error fetching data distribution:", error);
            });
        } else {
          
          // Blocco esistente per il caso in cui laps telemetry non sia vuoto
          setDistData(Array.isArray(res.data) ? res.data : []);
          if (Array.isArray(res.data)) {
            const requests = res.data.map((highlight) => {
              const driverSessions = [
                {
                  email: highlight.driver,
                  sessions: [
                    {
                      session: highlight.session,
                      laps: [highlight.lap],
                    },
                  ],
                },
              ];
              return axios.post(
                `${process.env.REACT_APP_API_DOMAIN}/get_filtered_longitude_latitude?race=${highlight.race}`,
                driverSessions,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              );
            });
            Promise.all(requests)
              .then((responses) => {
                const newDistTrajectories = responses.map((resp, idx) => ({
                  highlight: res.data[idx],
                  latLongData: resp.data[0],
                }));
                setDistTrajectories(newDistTrajectories);
              })
              .catch((error) => {
                console.error("Error calling get_filtered_longitude_latitude:", error);
              });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching laps telemetry:", error);
      });
  };

  console.log("dist trajectories", distTrajectories)
  useEffect(() => {
 
    fetchGivenLaps();
  }, [ selectedTeam, isCustom, distChange]);
  // -------------------
  // USEEFFECT PRINCIPALE
  // -------------------
  useEffect(() => {
    setIsLoading(true)
    const fetchClosestLap = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_closest_lap/`, {
          params: {
            track: searchTrack,
            lap_time: searchLapTime,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        });

        const lapTrajectory = response.data.closest_lap.lap_data.map((point) => ({
          lat: point?.latitude,
          lon: point?.longitude,
        }));

        setTrajectory(lapTrajectory || []);
        const processedData = response.data?.closest_lap?.processed_data;
        const newRedPoints = [];
        const newGreenPoints = [];

        let counter = 1;
        Object.values(processedData).forEach((sector) => {
          sector?.actions?.forEach((action) => {
            const [, actionType, , , lon, lat] = action;
            const point = {
              lat,
              lon,
              label: actionType === "A" ? "Accelerate" : "Brake",
              number: counter++,
            };
            if (actionType === "A") {
              newGreenPoints.push(point);
            } else if (actionType === "B") {
              newRedPoints.push(point);
            }
          });
        });

        setRedPoints(newRedPoints);
        setGreenPoints(newGreenPoints);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("Axios Error:", error);
      }
    };

    if (notSearch) {
      if (!selectedTrack) {
        if (showPersonalButton) {
          // getTrackUser();
          getTrackTeam();
        } else {
          getTrackTeam();
        }
      }
    } else {
      fetchClosestLap();
      getCentroids(searchTrack);
    }
  }, [, searchTrack, searchLapTime, differentTrack]); // Eseguito una sola volta al mount
  console.log('color  vector,', colorVector)

  // -------------------
  // USEEFFECT PER ANALYTICS TRAJECTORIES
  // -------------------
  useEffect(() => {
    if (isAnalytics && driversData?.length > 0) {

      setAnalyticsTrajectories(
        driversData?.map(obj =>
          obj?.drivingData?.map(pt => ({
            lat: pt?.latitude,
            lon: pt?.longitude,
          }))
        )
      );
    }
  }, [,isAnalytics, driversData]);

  // -------------------
  // FUNZIONI PER SHIFT DELLA TRAIETTORIA
  // -------------------
  const adjustPosition = (direction) => {
    // Aggiorna la traiettoria
    const newTrajectory = trajectory.map((point) => {
      let newPoint = { ...point };
      if (direction === 'N') {
        newPoint.lat += 0.00001;
      } else if (direction === 'S') {
        newPoint.lat -= 0.00001;
      } else if (direction === 'E') {
        newPoint.lon += 0.00001;
      } else if (direction === 'W') {
        newPoint.lon -= 0.00001;
      }
      return newPoint;
    });

    // Aggiorna i punti rossi
    const newRedPoints = redPoints.map((point) => {
      let newPoint = { ...point };
      if (direction === 'N') {
        newPoint.lat += 0.00001;
      } else if (direction === 'S') {
        newPoint.lat -= 0.00001;
      } else if (direction === 'E') {
        newPoint.lon += 0.00001;
      } else if (direction === 'W') {
        newPoint.lon -= 0.00001;
      }
      return newPoint;
    });

    // Aggiorna i punti verdi
    const newGreenPoints = greenPoints.map((point) => {
      let newPoint = { ...point };
      if (direction === 'N') {
        newPoint.lat += 0.00001;
      } else if (direction === 'S') {
        newPoint.lat -= 0.00001;
      } else if (direction === 'E') {
        newPoint.lon += 0.00001;
      } else if (direction === 'W') {
        newPoint.lon -= 0.00001;
      }
      return newPoint;
    });

    //   let newFinish = finish;
    // if (direction === 'N') {
    //   newFinish?.lat += 0.00001;
    // } else if (direction === 'S') {
    //   newFinish?.lat -= 0.00001;
    // } else if (direction === 'E') {
    //   newFinish.lon += 0.00001;
    // } else if (direction === 'W') {
    //   newFinish.lon -= 0.00001;
    // }


    setTrajectory(newTrajectory);
    setRedPoints(newRedPoints);
    setGreenPoints(newGreenPoints);
    adjustDistTrajectories(direction);
    // setFinish(newFinish);
  };

  const adjustDistTrajectories = (direction) => {
    console.log(`Adjusting distTrajectories: ${direction}`);
    setDistTrajectories(prevDistTrajectories =>
      prevDistTrajectories.map(obj => ({
        ...obj,
        latLongData: {
          ...obj.latLongData,
          drivingData: obj?.latLongData?.drivingData.map(pt => {
            let newPt = { ...pt };
            if (direction === 'N') newPt.latitude += 0.00001;
            else if (direction === 'S') newPt.latitude -= 0.00001;
            else if (direction === 'E') newPt.longitude += 0.00001;
            else if (direction === 'W') newPt.longitude -= 0.00001;
            return newPt;
          })
        }
      }))
    );
  };

  const adjustAnalyticsTrajectories = (direction) => {
    console.log(`Adjusting analytics trajectories: ${direction}`);
    setAnalyticsTrajectories(prevAnalytics =>
      prevAnalytics.map(trajectory =>
        trajectory?.map(point => {
          let newPoint = { ...point };
          if (direction === 'N') {
            newPoint.lat += 0.00001;
          } else if (direction === 'S') {
            newPoint.lat -= 0.00001;
          } else if (direction === 'E') {
            newPoint.lon += 0.00001;
          } else if (direction === 'W') {
            newPoint.lon -= 0.00001;
          }
          return newPoint;
        })
      )
    );
  };


  const getColorForData = (dataItem) => {
    // Se colorVector è vuoto o assente, non c'è colore personalizzato
    if (colorVector?.length == 0) return null;

    // Filtra in base a email e lap
    const found = colorVector.find(
      (obj) =>
        obj?.email === dataItem?.email &&
        obj?.lap === dataItem?.lap
    );

    // Se trova un match, restituisce found.color, altrimenti null
    return found?.color || null;
  };
  // -------------------
  // ICONE E SETTORI
  // -------------------
  const finishIcon = new L.Icon({
    iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(
      '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><rect width="30" height="30" fill="black"/><path d="M0,0 L30,30" stroke="white" stroke-width="2"/><path d="M30,0 L0,30" stroke="white" stroke-width="2"/></svg>'
    )}`,
    iconSize: [30, 30],
  });

  const createSectorIcon = (sectorNumber) =>
    new L.DivIcon({
      html: `<div style="display: flex; justify-content: center; align-items: center; width: 30px; height: 30px; border-radius: 50%; background: white; border: 2px solid black; font-weight: bold;">${sectorNumber + 1}</div>`,
      className: '',
      iconSize: [30, 30],
    });

  const colorScale = ["blue", "red", "green", "orange", "purple", "yellow", "pink", 'brown'];

  // -------------------
  // CALCOLO SEGMENTI
  // -------------------
  useEffect(() => {
    if (finish && typeof finish[0] === 'number' && trajectory.length > 0) {
      let minDist = Infinity;
      let closestPoint = finish;
      trajectory.forEach(pt => {
        const d = Math.sqrt(Math.pow(pt?.lat - finish[0], 2) + Math.pow(pt?.lon - finish[1], 2));
        if (d < minDist) {
          minDist = d;
          closestPoint = pt;
        }
      });
      // Aggiorna finish solo se il punto più vicino è diverso da quello corrente
      if (closestPoint[0] !== finish[0] || closestPoint[1] !== finish[1]) {
        setFinish(closestPoint);
      }
    }
  }, [, trajectory]);
  console.log("dist traj", distTrajectories)
  const segments = useMemo(() => {
    if (!trajectory || trajectory.length === 0) return [];

    const segmentIndices = [];
    let startIndex = 0;

    // Per ogni punto di settore, troviamo l'indice del punto della traiettoria più vicino
    sectorPoints.forEach(([sectorLat, sectorLon]) => {

      let minDist = Infinity;
      let bestIndex = startIndex;
      for (let j = startIndex; j < trajectory.length - 10; j++) {
        const point = trajectory[j];
        const dist = Math.sqrt(
          Math.pow(point?.lat - sectorLat, 2) + Math.pow(point?.lon - sectorLon, 2)
        );
        if (dist < minDist) {
          minDist = dist;
          bestIndex = j;
        }
      }
      segmentIndices.push(bestIndex);
      startIndex = bestIndex;
    });

    const normalSegments = [];
    for (let i = 0; i <= segmentIndices.length - 1; i++) {
      const startIdx = segmentIndices[i];
      const endIdx =
        i === segmentIndices.length - 1
          ? trajectory.length - 1
          : segmentIndices[i + 1];

      if (startIdx < endIdx) {
        normalSegments.push(trajectory.slice(startIdx, endIdx + 1));
      }
    }

    // Rimosso tutto ciò che riguardava la creazione di wrapSegment e il suo return
    return normalSegments;
  }, [trajectory, sectorPoints]);

  console.log('TRACJECTORY', segments)
  const segmentColors = segments.map((seg, index) => {
    if (sectorPoints.length === 9 && index === segments.length - 1) {
      return "orange";
    }
    return colorScale[index % colorScale.length];
  });

  const sectorMarkers = useMemo(() => {
    return segments.map((seg, index) => {
      const midIndex = Math.floor(seg.length / 2);
      return { point: seg[midIndex], sector: index };
    });
  }, [segments]);

  // -------------------
  // RENDER
  // -------------------
  const trajectoryColorMapping = useMemo(() => {
    const mapping = {};
    if (distTrajectories && distTrajectories.length > 0) {
      distTrajectories.forEach((obj, index) => {
        // Supponiamo che obj.highlight contenga i campi "lap" e "session"
        const { lap, session } = obj.highlight || {};
        if (lap !== undefined && session !== undefined) {
          mapping[`${lap}_${session}`] = colorScale[index % colorScale.length];
        }
      });
    }
    handleColorDist(mapping)
    return mapping;
  }, [distTrajectories]);
  return (
    <><Draggable bounds="parent" handle=".drag-handle">
      <div style={{ position: 'relative' }}>
        {/* Icona DRAG in alto a destra */}
        {/* <div style={{ position: 'absolute', top: 10, right: 10, zIndex: 9999 }}>
          <IconButton className="drag-handle" size="small" style={{ cursor: 'grab' }}>
            <OpenWithIcon />
          </IconButton>
        </div> */}

        {/* Bottone Align (Chip) per modalità non-analytics */}
        {trajectory.length > 0 && !isAnalytics && !isRecap && (
          <div style={{ position: 'absolute', top: '10px', right: '60px', zIndex: 9998 }}>
            <Chip
              label={showButtons ? "Close Align" : "Align"}
              onClick={() => setShowButtons(!showButtons)}
              style={{
                backgroundColor: 'white',
                borderRadius: '20px',
                cursor: 'pointer',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                fontSize: '20px',
                padding: '20px',
              }}
            />
          </div>
        )}

        {/* Bottone Align (Chip) per modalità analytics */}
        {isAnalytics && !showSectors && !isRecap && driversData?.length > 0 && (
          <div style={{ position: 'absolute', top: '10px', right: '60px', zIndex: 9998 }}>
            <Chip
              label={showButtons ? "Close Align" : "Align"}
              onClick={() => {
                revealButtons(showButtons ? true : false)
                setShowButtons(!showButtons);
              }}
              style={{
                backgroundColor: 'white',
                borderRadius: '20px',
                cursor: 'pointer',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                fontSize: '20px',
                padding: '20px',
              }}
            />
          </div>
        )}

        {/* Pannello per il posizionamento */}
        {showButtons && (
          <div style={{ position: 'absolute', top: '70px', right: '20px', zIndex: 1000 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              {/* Nord */}
              <IconButton
                onClick={() => isAnalytics ? adjustAnalyticsTrajectories('N') : adjustPosition('N')}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: '10px',
                  fontSize: '36px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                }}
              >
                <ArrowUpward />
              </IconButton>

              <div style={{ display: 'flex', gap: '10px' }}>
                {/* Ovest */}
                <IconButton
                  onClick={() => isAnalytics ? adjustAnalyticsTrajectories('W') : adjustPosition('W')}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: '10px',
                    fontSize: '36px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <ArrowBack />
                </IconButton>

                {/* Est */}
                <IconButton
                  onClick={() => isAnalytics ? adjustAnalyticsTrajectories('E') : adjustPosition('E')}
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    padding: '10px',
                    fontSize: '36px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <ArrowForward />
                </IconButton>
              </div>

              {/* Sud */}
              <IconButton
                onClick={() => isAnalytics ? adjustAnalyticsTrajectories('S') : adjustPosition('S')}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  padding: '10px',
                  fontSize: '36px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                }}
              >
                <ArrowDownward />
              </IconButton>
            </div>
          </div>
        )}

        {/* SEZIONE MAPPA */}
        {trajectory?.length > 0 && !isAnalytics ? (
          <MapContainer
            center={[trajectory[0]?.lat, trajectory[0]?.lon]}
            zoom={18}
            key={`${searchTrack}-${searchLapTime}-${trajectory}`}
            style={{ position: 'relative', height: "100vh", width: "100%" }}
          >
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              maxZoom={19.99}
            />

            {/* Marker traguardo */}
            {trajectory.length > 0 && finish && !isRecap && (
              <Marker position={finish} icon={finishIcon}>
                <Popup>Finish Line</Popup>
              </Marker>
            )}

            {/* {trajectory.length > 0  && (
              <Marker position={[trajectory[0]?.lat, trajectory[0].lon]}icon={finishIcon}>
                <Popup>Finish Line</Popup>
              </Marker>
            )} */}



            {/* Punti rossi (Brake) */}
            {redPoints.map((point, index) => (
              <Marker
                key={`red-${index}`}
                position={[point?.lat, point?.lon]}
                icon={new L.Icon({
                  iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(
                    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><circle cx="15" cy="15" r="10" fill="red" /></svg>'
                  )}`,
                  iconSize: [25, 25],
                })}
              >
                <Popup>{`Point ${point.number}: ${point.label}`}</Popup>
              </Marker>
            ))}

            {/* Punti verdi (Accelerate) */}
            {greenPoints.map((point, index) => (
              <Marker
                key={`green-${index}`}
                position={[point?.lat, point.lon]}
                icon={new L.Icon({
                  iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(
                    '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><circle cx="15" cy="15" r="10" fill="green" /></svg>'
                  )}`,
                  iconSize: [25, 25],
                })}
              >
                <Popup>{`Point ${point.number}: ${point.label}`}</Popup>
              </Marker>
            ))}

            {/* Segmenti colorati */}


            {(localShowSectors || distTrajectories.length == 0 || !notSearch) ? (
              segments.map((segment, index) => (
                <Polyline
                  key={index}
                  positions={segment.map((pt) => [pt?.lat, pt?.lon])}
                  color={segmentColors[index]}
                />
              ))
            ) : (
              /* Altrimenti, mostriamo i distTrajectories */

              distTrajectories.map((obj, idx) => {
                const lineData = obj?.latLongData?.drivingData?.map(pt => [pt.latitude, pt.longitude]);

                // Usa un array di colori e prendi l'indice modulo la lunghezza dell'array
                const colorScale = ["blue", "red", "green", "orange", "purple", "yellow", "pink", "brown"];
                const lineColor = colorScale[idx % colorScale.length];

                return (
                  <Polyline
                    key={idx}
                    positions={lineData}
                    color={lineColor}
                  />
                );
              })
            )}

            {/* Marker dei settori */}
            {(localShowSectors || distTrajectories.length == 0 || !notSearch) && sectorMarkers.map((marker, idx) => (
              <Marker key={`sector-marker-${idx}`} position={[marker.point?.lat, marker.point?.lon]} icon={createSectorIcon(marker.sector)}>
                <Popup>{`Sector ${marker.sector}`}</Popup>
              </Marker>
            ))}
          </MapContainer>
        ) : (
          <>
            {isLoading && !noData && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <CircularProgress />
              </div>
            )}
            {(driversData?.length === 0 || noData) && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography>No telemetry data inserted yet!</Typography>
                  <Chip
                    label="Insert Data"
                    color="primary"
                    onClick={navigateToTelemetryData}
                    sx={{ mt: 2 }}
                  />
                </Box>
              </div>
            )}

            {/* Se non ho trajectory, ma ho driversData => multi polilinee per analytics */}
            {isAnalytics && driversData?.length > 0 && (
              <MapContainer center={center} zoom={18} style={{ height: "100vh", width: "100%" }} key={`${differentTrack}-`}>

                {/* MARKER */}
                {driversData?.map((data, index) => {
                  // 1) Ricava lat/long
                  const latOffset = analyticsTrajectories.length > 0 ? analyticsTrajectories[0][0]?.lat - driversData[0]?.drivingData[0]?.latitude : 0;
                  const lonOffset = analyticsTrajectories.length > 0 ? analyticsTrajectories[0][0]?.lon - driversData[0]?.drivingData[0]?.longitude : 0;

                  // 1) Ricava lat/long con offset calcolato
                  const ballLat = (data?.drivingData?.[trackIndex * 4]?.latitude || 0) + latOffset;
                  const ballLon = (data?.drivingData?.[trackIndex * 4]?.longitude || 0) + lonOffset;
                  console.log("lat long point", ballLat, ballLon)

                  // 2) Trova il colore personalizzato (email + lap)
                  const customColor = getColorForData(data);
                  // Fallback su un array di colori standard
                  const markerColor = customColor || colorScale[index % colorScale.length];

                  return (
                    ballLat && ballLon && (
                      <Marker
                        key={`analytics-marker-${index}`}
                        position={[ballLat, ballLon]}
                        icon={new L.Icon({
                          iconUrl: `data:image/svg+xml;utf8,${encodeURIComponent(`
                         <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                           <circle cx="15" cy="15" r="10" fill="${markerColor}" stroke="black" stroke-width="2" />
                         </svg>`)}
                       `,
                          iconSize: [20, 20],
                        })}
                      />
                    )
                  );
                })}

                <TileLayer
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  maxZoom={19.99}
                />

                {/* POLYLINE */}
                {!showSectors && analyticsTrajectories?.map((trajectory, index) => {
                  // Associare la polyline all'oggetto driversData corrispondente
                  const data = driversData[index];
                  // Stessa logica per trovare il colore
                  const customColor = getColorForData(data);
                  const lineColor = customColor || colorScale[index % colorScale.length];

                  return (
                    <Polyline
                      key={index}
                      positions={trajectory.map((p) => [p?.lat, p?.lon])}
                      color={lineColor}
                    />
                  );
                })}

                {trajectory?.length > 0 && finish && (
                  <Marker position={finish} icon={finishIcon}>
                    <Popup>Finish Line</Popup>
                  </Marker>
                )}
                {showSectors && segments.map((segment, index) => (
                  <Polyline
                    key={`segment-${index - 1}`}
                    positions={segment.map((pt) => [pt?.lat, pt?.lon])}
                    color={segmentColors[index]}
                  />
                ))}

                {/* Marker dei settori */}
                {showSectors && sectorMarkers.map((marker, idx) => (
                  <Marker key={`sector-marker-${idx}`} position={[marker.point?.lat, marker.point?.lon]} icon={createSectorIcon(marker.sector)}>
                    <Popup>{`Sector ${marker.sector}`}</Popup>
                  </Marker>
                ))}
              </MapContainer>
            )}
          </>
        )}
      </div>

    </Draggable>

    </>



  );

};

export default TrackMapEarth;
