import { Box, Hidden } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';
import DriversDates from 'components/Steppers/DriversDates/DriversDates.js';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArchiveIcon from '@mui/icons-material/Archive';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import CorniceEngine from './CorniceEngine';
import CorniceFront from './CorniceFront';
import CornicePressure from './CornicePressure';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Mmenu from '@mui/material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OutlinedInput from '@mui/material/OutlinedInput';
import { PieChart } from '@mui/x-charts/PieChart';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { useTheme } from '@mui/material/styles';

const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [racingData, setRacingData] = useState([]);
  const [selectedDriverDates, setSelectedDriverDates] = useState('');
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [showFront, setShowFront] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [lastSelectedDriver, setLastSelectedDriver] = useState(null);
  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [showEngine, setShowEngine] = useState(true);
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [step, setStep] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
    const [secondOpen, setSecondOpen] = useState(false);
  const [openDriverDates, setOpenDriverDates] = useState(true);
  const [stepPayload, setStepPayload] = useState({
    'race': race,
    'day': day,
    'drivers': [],
    
  });
  const [selectedSessions, setSelectedSessions] = useState([1]);

  const handleArrayChange = (array) => {
    console.log("array changed", array);
    setStepPayload(array);
    setDriver(array.drivers.map(driver => driver.value));
    setDay(array.day);
    setRace(array.race);
  };

  const handleFilterClick = (number, name) => {
    setOpenDriverDates(true);
    setStep(number);
    setSecondOpen(true)
    setCompleted(false);
    if (name) {
      setSelectedDriverDates(name);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0);
    setShowFront(newValue === 1);
    setShowPressure(newValue === 2);
  };

  const handleSessionClick = (session) => {
    setSelectedSessions((prev) => {
      if (prev.includes(session)) {
        return prev.filter((item) => item !== session);
      } else {
        return [...prev, session];
      }
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleMenuClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;

  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  console.log("selected team", selectedTeam.id);

  useEffect(() => {
    getDrivers(false);
  }, [selectedTeam]);

  const handleDoneClick = (event) => {
    event.stopPropagation();
    setSelectOpen(false);
  };

  const getDrivers = async (filter, racingData) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data);
          setDriver([res.data[0].email]);
        } else {
          setDrivers(res.data);
          setEmailName(res.data);
        }
        if (filter) {
          const racingDataEmails = racingData.map(item => item.email);
          const filteredDrivers = res.data.filter(driver => racingDataEmails.includes(driver.email));
          setDrivers(filteredDrivers);
          setEmailName(filteredDrivers);
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  useEffect(() => {
    setCompetitionLoading(true);
    // const fetchCompetitions = async () => {
    //   try {
    //     const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`,  {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //         accept: "application/json",
    //       },
    //     });
    //     const data = response.data;
    //     setCompetitions(data);
    //     setCompetitionLoading(false);
    //   } catch (error) {
    //     setCompetitionLoading(false);
    //     console.error('Error fetching competitions:', error);
    //   }
    // };

    // fetchCompetitions();
  }, [selectedTeam]);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_DOMAIN}/get_engine_graph_data?email=${driver}&race=${race}&day=${day}&team_id=${selectedTeam.id}`;
    axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
    })
      .then((response) => {
        const data = response.data;
        setRacingData(data);
        getDrivers(true, data);
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  }, [race, day, selectedTeam]);

  const handleChange = (event) => {
    const selectedDrivers = event.target.value;
    const { value } = event.target;
    if (value.length > 0) {
      setLastSelectedDriver(value[value.length - 1]);
    } else {
      setLastSelectedDriver(null);
    }
    setDriver(selectedDrivers);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDay = (day) => {
    setDay(day);
    handleMenuClose();
  };

  const racingDays = [
    { name: 'First Racing Day', value: 1 },
    { name: 'Second Racing Day', value: 2 },
    { name: 'Third Racing Day', value: 3 },
    { name: 'Fourth Racing Day', value: 4 },
    { name: 'Fifth Racing Day', value: 5 },
    { name: 'Sixth Racing Day', value: 6 },
    { name: 'Seventh Racing Day', value: 7 },
  ];
  const comp = competitions?.find((competition) => competition.id === race);

  return (
    <div>
      <DriversDates   secondOpen={secondOpen} completed={completed} setCompleted={setCompleted} stepPayload={stepPayload} setStepPayload={setStepPayload} isOpen={openDriverDates} setIsOpen={setOpenDriverDates} setStep={setStep} arrayChange={handleArrayChange} step={step} />

      <Grid>
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <Typography sx={{ fontSize: 40 }} > Setup analytics</Typography>
        </Grid>
        <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', marginBottom: 30 }}>
          <Chip label={comp ? `${comp.competition} (${comp.track})` : "choose race"} icon={<SwapHorizIcon />} onClick={() => handleFilterClick(0)} />
          <Chip label={day ? ` Day ${day}  ` : 'choose day'} onClick={() => handleFilterClick(1)} />
          {!showPersonalButton && ( <Chip label={driver?.length > 0 ? 'change drivers' : 'choose drivers'} onClick={() => handleFilterClick(2)} />)}
          <div style={{ display: 'flex', gap: 5, flexDirection: isMobile ? 'column' : 'row' }}>
          {/* {!isMobile && (
      <Typography sx={{ marginLeft: 3, marginTop: 0.8, marginRight: 2 }}>Session Number:</Typography>
    )}
      {isMobile && (
        <Typography sx={{ marginLeft: 3, marginTop: 0.8, marginRight: 2 }}>Session Number:</Typography>
      )} */}
      <div>
      {[1, 2, 3, 4, 5, 6].map((session) => (
        <Chip
          key={session}
          label={session}
          color={selectedSessions.includes(session) ? 'primary' : 'default'}
          onClick={() => handleSessionClick(session)}
          sx={{marginRight: 1}}
        />
      ))}

      </div>
      
    </div>
    
        </div>
      </Grid>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab}>
            <Tab label="ENGINE" {...a11yProps(0)} />
            <Tab label="CHASSIS" {...a11yProps(1)} />
            <Tab label="PRESSURE" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <div className='graphs'>
          <>
            {showEngine && <CorniceEngine race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} selectedSessions={selectedSessions}/>}
            {showFront && <CorniceFront race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} selectedSessions={selectedSessions}/>}
            {showPressure && <CornicePressure race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} selectedSessions={selectedSessions}/>}
          </>
        </div>
      </Box>
    </div>
  );
};

export default observer(PieChartWithSelect);
