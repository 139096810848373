import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Typography
} from '@mui/material';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import data1 from './TryFiles/telemetryTable.json';
import { useHistory } from 'react-router-dom';


import Skeleton from '@mui/material/Skeleton';
import axios from "axios";

const LapTable = ({ drivers, race, day, isTry,setLapTimesMap = () => {}, laps, fullName, selectedDriver, selectedDriverEmail, completed, setDriverSessions, driverSessions, wait }) => {
  const [dataset, setDataset] = useState(isTry ? data1?.find((session) => session?.email === selectedDriverEmail) || {} : []);
  const [givenDriverSessions, setGivenDriverSessions] = useState(isTry ? [1] : []);
  const [selectedSessionNumbers, setSelectedSessionNumbers] = useState([1]);
  const [sessionNumber, setSessionNumber] = useState(1);
  const [sessionNumbersByDriver, setSessionNumbersByDriver] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const history = useHistory();

  const rows1 = dataset && Array.isArray(dataset.laps) ? dataset.laps.map((lapData) => {
    const formatLapTime = (lapTime) => {
      // Ottieni i minuti e i secondi
      const minutes = Math.floor(lapTime / 60); // Divisione intera per ottenere i minuti
      const seconds = Math.floor(lapTime % 60); // Resto per ottenere i secondi interi
      const centiseconds = Math.round((lapTime % 1) * 100); // Centisecondi arrotondati
  
      // Ritorna il tempo formattato come MM:SS.CC
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${centiseconds.toString().padStart(2, '0')}`;
    };
  
    return {
      Lap: lapData.lap,
      Email: dataset.email,
      'Lap Time': formatLapTime(lapData.laptime),
      'Max RPM': lapData.MaxRpm,
      'Max Speed': lapData.MaxSpeed,
      'Max TK': lapData.MaxTK,
    };
  }) : [];
  const selectedLaps = useMemo(() => {
    const sessionsByDriver = driverSessions.find(session => session.email === selectedDriverEmail);
    return sessionsByDriver?.sessions.find((s) => s.session === sessionNumber)?.laps || []
  }, [driverSessions, sessionNumber, selectedDriverEmail])
  
  
  const [rows, setRows] = useState(isTry ? rows1 : []);

  console.log('real dataset', dataset)
  const [isLoading, setIsLoading] = useState(false);

 

  const getTelemetryTable = async () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/telemetry_table`, {
        params: {
          driver_emails: selectedDriverEmail,
          race,
          session: sessionNumber,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        debugger
        if (!isTry) {
          setDataset(res.data)
          console.log("dataset table fetched", res.data);
          console.log('selected driver email is', selectedDriverEmail);
          // Find the data corresponding to the selected driver and session number
          const data = res.data?.find(item => item.sessionNumber === sessionNumber && item.email === selectedDriverEmail);
          // Format the data into rows if it exists
          const formattedRows = data ? data.laps.map((lapData) => {
            const formatLapTime = (lapTime) => {
              // Calcola i minuti, secondi e centesimi di secondo
              const minutes = Math.floor(lapTime / 60); // Minuti interi
              const seconds = Math.floor(lapTime % 60); // Secondi interi
              const centiseconds = Math.round((lapTime % 1) * 100); // Centisecondi
          
              // Ritorna il tempo formattato come MM:SS.CC
              return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${centiseconds.toString().padStart(2, '0')}`;
            };
          
            return {
              Lap: lapData.lap,
              Email: data.email,
              'Lap Time': formatLapTime(lapData.laptime),
              // 'Split 1': lapData.Split_1, // Decommenta se necessario
              // 'Split 2': lapData.Split_2, // Decommenta se necessario
              // 'Split 3': lapData.Split_3, // Decommenta se necessario
              // 'Split 4': lapData.Split_4, // Decommenta se necessario
              'Max RPM': lapData.MaxRpm,
              'Max Speed': lapData.MaxSpeed,
              'Max TK': lapData.MaxTK,
            };
          }) : [];
          setRows(formattedRows)
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Axios Error:", error);
      });
  };
  useEffect(() => {
    if (!selectedDriver || selectedLaps.length === 0) return;
  
    const lapTimes = selectedLaps.reduce((acc, lap) => {
      const lapData = rows.find(r => r.Lap === lap);
      if (lapData) {
        acc[lap] = lapData['Lap Time'];
      }
      return acc;
    }, {});
  
    const structuredLapTimes = {
      [fullName]: {
        [`Session ${sessionNumber}`]: lapTimes
      }
    };
  
    setLapTimesMap(race);
  }, [selectedLaps, rows, fullName, sessionNumber]);
  
  
  useEffect(() => {
    getTelemetryTable();
  }, [sessionNumber, race, drivers, selectedDriverEmail, completed]);

  const getSessionsByDriver = async () => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/sessions_for_driver`, {
        params: {
          user_email: selectedDriverEmail,
          race,
          day: day,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setGivenDriverSessions(res.data);

        if (sessionNumber) {
          setSessionNumber(res?.data[0])
        }
        //setGivenDriverSessions([1,2]);
        console.log("sessions given", res.data)
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  useEffect(() => {
    getSessionsByDriver();
  }, [selectedDriverEmail]);
  const handleInsertTelemetryData = () => {
    history.push('/racing-space/telemetry-data'); // Naviga alla route specificata
  };

  const handleCompareChange = (lap) => {
    const copyDriverSessions = JSON.parse(JSON.stringify(driverSessions))
    let sessionsByDriver = copyDriverSessions.find(session => session.email === selectedDriverEmail);
    if (!sessionsByDriver) {
      // create driver
      sessionsByDriver = {
        email: selectedDriverEmail,
        sessions: []
      }
      copyDriverSessions.push(sessionsByDriver)
    }
    if (sessionsByDriver?.sessions && sessionsByDriver?.sessions?.length === 0) {
      // is empty fill with base data
      sessionsByDriver.sessions.push({
        session: sessionNumber,
        laps: [lap]
      })
    } else {
      const hasLaps = sessionsByDriver.sessions.find((s) => s.session === sessionNumber)
      if (!hasLaps) {
        // create lap
        sessionsByDriver.sessions.push({ session: sessionNumber, laps: [] })
      }
      const lapsByDriver = sessionsByDriver.sessions.find((s) => s.session === sessionNumber)
      const isLapPresent = lapsByDriver.laps.indexOf(lap)
      if (isLapPresent >= 0) {
        // remove lap
        lapsByDriver.laps.splice(isLapPresent, 1)
      } else {
        // add lap
        lapsByDriver.laps.push(lap)
      }
    }

    setDriverSessions(() => {
      return copyDriverSessions;
    })
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 430);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 430);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: '16px' }}>
        {givenDriverSessions.map((number) => (
          <Chip
            key={number}
            label={`${number}`}
            color={sessionNumber === number ? 'primary' : 'default'}
            variant={sessionNumber === number ? 'contained' : 'outlined'}
            onClick={() => setSessionNumber(number)}
            clickable
          />
        ))}
      </Box>

      {isLoading ? (
        <Skeleton sx={{ height: '70vh', marginTop: -18, marginBottom: -15 }} />
      ) : rows.length > 0 ? (
        <TableContainer
          component={Paper}
          style={{
            width: '100%',
            maxHeight: 'calc(100vh - 300px)',
            overflowY: 'auto',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="5%" padding="checkbox" /> {/* Cella più stretta */}
                <TableCell>Lap</TableCell>
                <TableCell>Lap Time</TableCell>
                <TableCell>Max RPM</TableCell>
                <TableCell>Max Speed</TableCell>
                <TableCell>Max TK</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleCompareChange(row.Lap)}
                  style={{
                    backgroundColor: selectedLaps.includes(row.Lap) ? '#e3f2fd' : 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <TableCell width="5%" padding="checkbox">
                    <Checkbox
                      checked={selectedLaps.includes(row.Lap)}
                      onChange={() => handleCompareChange(row.Lap)}
                      onClick={(e) => e.stopPropagation()} // Previene l'attivazione del click sulla riga
                    />
                  </TableCell>
                  <TableCell style={{ paddingLeft: '-70px' }}>{row.Lap}</TableCell>
                  <TableCell>{row['Lap Time']}</TableCell>
                  <TableCell>{row['Max RPM']}</TableCell>
                  <TableCell>{row['Max Speed']}</TableCell>
                  <TableCell>{row['Max TK']}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box textAlign="center" sx={{ mt: 4 }}>
          <Typography sx={{ mb: 2, fontSize: '1rem', color: 'gray' }}>
            No telemetry data available
          </Typography>
          <Chip
            label="Insert Telemetry Data"
            clickable
            onClick={handleInsertTelemetryData}
            sx={{
              fontSize: '1rem',
              padding: '12px 24px',
              backgroundColor: '#1976d2',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#115293',
              },
            }}
          />
        </Box>
      )}
    </Box>

  );
};

export default LapTable;


