import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Stack,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,

} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import CheckIcon from '@mui/icons-material/Check';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Pricing from "components/pages/Pricing";
import "react-toastify/dist/ReactToastify.css";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ContactInside from "components/pages/ContactInside";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CardLoader from "components/Loaders/CardLoader";
import HttpProvider from "services/HttpProvider";
import remove from "lodash/remove";
import myUserStore from "../../stores/UserStore";
import TrackMapEarth from "components/pages/TrackMapEarth";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { observer } from "mobx-react-lite";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from "@mui/material";
import { ThumbDown } from "@mui/icons-material";
import { maxHeight } from "@mui/system";

/**
 * Simple progress bar with percentage label
 */
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: 50, mr: 1 }} hidden={props.status !== "processing"}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}



function FilesRecap({
  refreshList = false,
  useLinkToPage = false,
  length = 5,
  onComplete = () => { },
  sx = {},
  ...props
}) {
  const [showPricing, setShowPricing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProcessing] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null); // For Telemetry
  const showPersonalButton = myUserStore.isPersonalSpace;
  const selectedTeam = myUserStore.getSelectedTeam;
  const [multipleViewMode, setMultipleViewMode] = useState(false);
  const [selectedMultipleVideos, setSelectedMultipleVideos] = useState([]);
  const [openMultipleViewDialog, setOpenMultipleViewDialog] = useState(false);
  const [editFileId, setEditFileId] = useState(null);
  const [status, setStatus] = useState(null);
  const [chosenVideo, setChosenVideo] = useState([]);
  const [editNameValue, setEditNameValue] = useState("");
  const [chosenTrack, setChosenTrack] = useState("");
  const [thumbnails, setThumbnails] = useState({});
  const [showNotes, setShowNotes] = useState(false);
  const [currentNote, setCurrentNote] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState([]);
  const [emailName, setEmailName] = useState([]);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  // Dialog aperto/chiuso
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  // Video attualmente in editing
  const [videoToEdit, setVideoToEdit] = useState(null);

  // Campi temporanei per Name, Session, Race, Driver
  const [tempName, setTempName] = useState("");
  const [tempSession, setTempSession] = useState("");
  const [tempRace, setTempRace] = useState("");
  const [tempDriver, setTempDriver] = useState("");

  // 1) Dialog for Telemetry 
  const [editFileDialogOpen, setEditFileDialogOpen] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);

  const [tempFileName, setTempFileName] = useState("");
  const [tempFileSession, setTempFileSession] = useState("");
  const [tempFileRace, setTempFileRace] = useState("");
  const [tempFileDriver, setTempFileDriver] = useState("");


  const [showTrackMap, setShowTrackMap] = useState(false);
  const status1 = myUserStore.status
  const [myPlan, setMyPlan] = useState(myUserStore?.subscriptionDetails?.custom_id);
  console.log("my plamn", myPlan)
  // Track competitions to show raceName, etc.
  const [competitions, setCompetitions] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);

  // Onboard (video) variables
  const [videos, setVideos] = useState([]); // Onboard videos
  const [activeTab, setActiveTab] = useState("TELEMETRY");
  const [videoId, setVideoId] = useState(null); // For viewing a single video in modal
  const [videoLoading, setVideoLoading] = useState(false);

  // Toggles single-video edit/delete icons on each video
  const [manageMode, setManageMode] = useState(false);
  // For rename
  const [videoBeingEdited, setVideoBeingEdited] = useState(null);
  const [newVideoName, setNewVideoName] = useState("");
  console.log("team id ", selectedTeam.id)
  // Search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data)
          setDriver([res.data[0].email])

        } else {
          setDrivers(res.data)
          // if (isDriver) {
          //   const myDriver = res.data.find(item => item.email === myEmail);
          //   setDriver([myDriver?.email])
          // }

        }

        console.log("Drivers API Response:", res.data.filter(driver => driver.role === "DRV"));
        setEmailName(res.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [, selectedTeam]);
  // const [filteredOnboardFiles, setFilteredOnboardFiles] = useState([]); 
  const [onboardFiles, setOnboardFiles] = useState([]); // List of onboard files
  const fetchComment = async (fileId) => {
    debugger
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_DOMAIN}/get_video_comment?file_id=${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json"
          }
        }
      );
      setCurrentNote(data?.comment || "");
    } catch (error) {
      console.error("Error fetching comment:", error);
    }
  };
  const toggleSelectVideo = (documentId) => {
    setSelectedMultipleVideos((prev) => {
      if (prev.includes(documentId)) {
        return prev.filter((id) => id !== documentId);
      }
      return [...prev, documentId];
    });
  };
  /**
   * 
   * Filter TELEMETRY files by search query
   */
  const filteredFiles = useMemo(() => {
    return files.filter((file) =>
      file.Name?.toLowerCase().startsWith(searchQuery)
    );
  }, [files, searchQuery]);


  const filteredVideos = useMemo(() => {
    return videos.filter((vid) =>
      vid.filename?.toLowerCase().includes(searchQuery)
    );
  }, [videos, searchQuery]);

  /**
   * Load Telemetry files
   */
  const loadFiles = useCallback(() => {
    setIsLoading(true);

    const endpoint = showPersonalButton
      ? `/files_uploaded_for_team`
      : `/files_uploaded_for_team`;

    const params = !showPersonalButton
      ? { team_id: selectedTeam.id }
      : { team_id: selectedTeam.id};

    return HttpProvider.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
      params,
    })
      .then(async (res) => {
        const { data = [] } = res;
        const processingArray = remove(data, (d) => d.status === "processing");
        setProcessing(processingArray);
        setFiles(data);
        return data;
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showPersonalButton, selectedTeam]);
  const handleMultipleViewClick = () => {
    // Apri il dialog
    debugger
    setOpenMultipleViewDialog(true);

    // Trova l'oggetto video in "Video" con document_id uguale a selectedMultipleVideos[0]
    const videoTrack = videos.find(
      (video) => video.document_id === selectedMultipleVideos[0]
    );

    // Estrai la chiave "race" dall'oggetto video trovato
    const raceId = videoTrack?.race;

    // Trova la competizione con l'id corrispondente a raceId e prendi la traccia
    const trackFound = competitions.find(
      (comp) => comp.id === raceId
    )?.track;
    setChosenTrack(trackFound)
    console.log("Track trovata:", trackFound);

    return trackFound;
  };

  /**
   * Fetch onboard videos
   */
  // 1) Definisci correttamente la funzione
  //    getVideoStatusAndThumbnail(libraryId, videoId).
  //    Restituisce un oggetto con { status, thumbnailUrl }.
  const getVideoStatusAndThumbnail = async (libraryId, videoId) => {
    try {
      const url = `https://video.bunnycdn.com/library/${libraryId}/videos/${videoId}/play`;
      const response = await axios.get(url, {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          AccessKey: "83d006c0-f495-4a2f-b2937024639c-b620-4a18", // La tua Access Key
        },
      });
      debugger
      const { thumbnailUrl } = response.data || {};
      // Esempio di URL thumbnail (adatta con il tuo Pull Zone se serve)

      return { thumbnailUrl };
    } catch (error) {
      console.error("Errore durante il recupero del video:", error);

    }
  };
  console.log("thumbnail", thumbnails)

  // Funzione per salvare il commento subito a ogni carattere
  const saveNoteOnChange = async (newNote) => {
    if (!chosenVideo?.document_id) return; // Se manca l'ID del video, esci
    try {
      const encodedNote = encodeURIComponent(newNote);
      await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/update_video_comment?file_id=${chosenVideo.document_id}&new_comment=${encodedNote}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json"
          }
        }
      );
      // Se vuoi, mostra un toast di feedback (ma attento allo spam)
      // toast.success("Note saved");
    } catch (error) {
      console.error("Error saving comment on typing:", error);
    }
  };


  useEffect(() => {
    const fetchOnboardVideos = async () => {
      try {
        setIsLoading(true);
        // 2) Carica i video dal tuo endpoint
        const res = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/list_videos?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        const videosData = res.data || [];
        setVideos(videosData);

        // 3) Per ogni video, chiama getVideoStatusAndThumbnail con i parametri corretti

        videosData.forEach(async (vid) => {

          // libraryId fisso, es: 361126
          // videoId = vid.video_id (assicurati che contenga l’ID bunny)

          const { thumbnailUrl } = await getVideoStatusAndThumbnail(361126, vid.video_id) || "";

          // 4) Salva solo la stringa della thumbnail in state
          setThumbnails((prev) => ({
            ...prev,
            [vid?.document_id]: thumbnailUrl,
          }));
        });

      } catch (error) {
        console.error("Error fetching onboard videos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOnboardVideos();
  }, [selectedTeam, activeTab]);


  /**
   * Opens video in a modal
   */

  const handleVideoClick = (video) => {
    setVideoLoading(true);
    setVideoId(video.video_id);
    setChosenVideo(video);

    // Ricava la track dal competition corrispondente
    const trackFound = competitions.find((comp) => comp.id === video.race)?.track;
    setChosenTrack(trackFound);
    debugger

    (async () => {
      const videoStatus = await getVideoStatus(361126, video.video_id);
      setStatus(videoStatus);
    })();
  };
  // Esempio: getVideoStatusAndThumbnail
  // - Ritorna un oggetto con { status, thumbnailUrl }
  // - Usa lo stesso endpoint “play” e legge “thumbnailFileName”


  const getVideoStatus = async (libraryId, videoId) => {
    try {
      const url = `https://video.bunnycdn.com/library/${libraryId}/videos/${videoId}/play`;

      const response = await axios.get(
        url,
        // Corpo della richiesta con il titolo del video
        {
          headers: {
            accept: 'application/json', // Specifica il formato della risposta
            'content-type': 'application/json', // Specifica il tipo di contenuto
            AccessKey: '83d006c0-f495-4a2f-b2937024639c-b620-4a18', // Sostituisci con la tua Access Key
          },
        }
      );

      console.log('Video creato con successo:', response.data?.video?.status);

      return response.data?.video?.status;
      // Restituisce i dettagli del video creato
    } catch (error) {
      console.error('Errore durante la creazione del video:', error.response?.data || error.message);
      //throw error;
    }
  };


  /**
   * Onboard video embed URL
   */
  const embedUrl = `https://iframe.mediadelivery.net/embed/361126/${videoId}?autoplay=true&loop=false&muted=false&preload=true&responsive=true`;

  /**
   * Single-video delete from the Manage UI
   */
  const handleOnboardDeleteVideo = async (documentId) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_API_DOMAIN}/delete_video?file_id=${documentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      // Remove from local state
      setVideos((prev) => prev.filter((vid) => vid.document_id !== documentId));
      // toast.success("Video deleted successfully");
    } catch (error) {
      console.error("Error deleting video:", error);
      toast.error("Failed to delete video");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Start rename mode for a single video
   */
  // Al posto della vecchia handleOnboardEdit che mostrava <TextField />
  const handleOnboardEdit = (video) => {
    // Salva il video selezionato
    setVideoToEdit(video);

    // Precarica i campi con i valori attuali
    setTempName(video.filename || "");
    setTempSession(video.session || "");
    setTempRace(video.race || "");
    setTempDriver(video.driver || "");

    // Apri il dialog
    setEditDialogOpen(true);
  };

  console.log("temp session", tempSession)
  /**
   * Confirm rename for the single video
   */
  const handleRenameVideo = async () => {
    if (!videoBeingEdited) return;
    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_DOMAIN}/update_video_name?file_id=${videoBeingEdited}&new_filename=${newVideoName}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      // Update local array with new filename
      setVideos((prev) =>
        prev.map((v) =>
          v.document_id === videoBeingEdited
            ? { ...v, filename: newVideoName }
            : v
        )
      );
      // toast.success("Filename updated successfully");
    } catch (error) {
      console.error("Error updating video name:", error);
      toast.error("Failed to update video name");
    } finally {
      setVideoBeingEdited(null);
      setNewVideoName("");
      setIsLoading(false);
    }
  };

  const handleCloseVideo = () => {
    setVideoLoading(false);
    setVideoId(null);
  };
  const saveVideoEdits = async () => {
    if (!videoToEdit) return;
    try {
      // Se ha cambiato il name
      if (tempName !== videoToEdit.filename) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_video_name`,
          null,
          {
            params: {
              file_id: videoToEdit.document_id,
              new_filename: tempName
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json"
            }
          }
        );
      }

      // Se ha cambiato la Race
      if (tempRace !== videoToEdit.race) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_video_race`,
          null,
          {
            params: {
              file_id: videoToEdit.document_id,
              new_race: tempRace
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json"
            }
          }
        );
      }

      // Se ha cambiato il Driver
      if (tempDriver !== videoToEdit.driver) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_video_driver`,
          null,
          {
            params: {
              file_id: videoToEdit.document_id,
              new_driver: tempDriver
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json"
            }
          }
        );
      }

      // Se ha cambiato la Session
      if (tempSession !== videoToEdit.session) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_video_session`,
          null,
          {
            params: {
              file_id: videoToEdit.document_id,
              new_session: tempSession
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json"
            }
          }
        );
      }

      // Chiudi dialog
      setEditDialogOpen(false);

      // Aggiorna la lista video localmente (facoltativo):
      setVideos((prev) =>
        prev.map((v) =>
          v.document_id === videoToEdit.document_id
            ? {
              ...v,
              filename: tempName,
              session: tempSession,
              race: tempRace,
              driver: tempDriver
            }
            : v
        )
      );
    } catch (error) {
      console.error("Error saving video edits:", error);
    }
  };


  /**
   * Render ON BOARD videos
   */
  const renderOnboardList = () => {
    if (videos.length === 0) {
      return <Typography variant="body1">No onboard videos available.</Typography>;
    }

    return (

      <>

        <Dialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          PaperProps={{
            sx: {
              width: { xs: '90%', md: '70%', lg: '50%' },  // o come preferisci
              maxWidth: '900px',
              backgroundColor: '#f5f5f5',
              borderRadius: '12px',
              p: 3
            }
          }}
        >
          <DialogTitle>Edit Video Info</DialogTitle>

          <DialogContent sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* NAME */}
            <TextField
              label="Name"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              fullWidth
              sx={{ marginTop: 2 }}
            />

            {/* SESSION */}
            <Typography variant="h6" sx={{ mb: 0, color: 'black' }}>
              Session Number:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <Chip
                  key={num}
                  label={num}
                  color="default"
                  size="medium"
                  variant={tempSession === num ? "contained" : "outlined"}
                  onClick={() => setTempSession(num)}
                />
              ))}
            </Box>

            {/* RACE */}
            <FormControl fullWidth >
              <InputLabel>Race</InputLabel>
              <Select
                value={tempRace}
                onChange={(e) => setTempRace(e.target.value)}
                label="Race"
              >
                <MenuItem sx={{ maxHeight: '100px' }} value="">(None)</MenuItem>
                {/* Mappa le tue competizioni qui */}
                {competitions.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.competition}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* DRIVER */}
            <FormControl fullWidth>
              <InputLabel>Driver</InputLabel>
              <Select
                value={tempDriver}
                onChange={(e) => setTempDriver(e.target.value)}
                label="Driver"
              >
                <MenuItem value="">(None)</MenuItem>
                {/* Mappa i tuoi driver qui */}
                {drivers.map((drv) => (
                  <MenuItem key={drv.email} value={drv.email}>
                    {drv.surname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={saveVideoEdits}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
            gap: 2,
          }}
        >

          {filteredVideos.map((video) => {
            const isBeingEdited = videoBeingEdited === video.document_id;

            return (
              <Box
                key={video.document_id}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => {
                  if (multipleViewMode) {
                    toggleSelectVideo(video.document_id);
                    const videoTrack = videos.find(
                      (video) => video.document_id === video.document_id
                    );

                    // Estrai la chiave "race" dall'oggetto video trovato
                    const raceId = videoTrack?.race;

                    // Trova la competizione con l'id corrispondente a raceId e prendi la traccia
                    const trackFound = competitions.find(
                      (comp) => comp.id === raceId
                    )?.track;
                    setChosenTrack(trackFound)
                  } else if (!manageMode && !isBeingEdited) {
                    // Apri la visione multipla con un solo video
                    debugger
                    setSelectedMultipleVideos([video.document_id]);
                    setOpenMultipleViewDialog(true);
                    const videoTrack = videos.find(
                      (videox) => videox.document_id === video.document_id
                    );

                    // Estrai la chiave "race" dall'oggetto video trovato
                    const raceId = videoTrack?.race;

                    // Trova la competizione con l'id corrispondente a raceId e prendi la traccia
                    const trackFound = competitions.find(
                      (comp) => comp.id === raceId
                    )?.track;
                    setChosenTrack(trackFound)
                  }
                }}

              >
                {multipleViewMode && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "8px",
                      left: "8px",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      border: "2px solid #000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectVideo(video.document_id);
                    }}
                  >
                    {selectedMultipleVideos.includes(video.document_id) && (
                      <CheckIcon sx={{ fontSize: "16px" }} />
                    )}
                  </Box>
                )}
                {/* <OndemandVideoIcon
                  sx={{
                    fontSize: { xs: "50px", sm: "75px", md: "100px" },
                    color: "#757575",
                    display: "block",
                    margin: "20px auto",
                  }}
                /> */}
                <img
                  src={thumbnails[video?.document_id] || ""}
                  alt="thumbnail"
                  style={{
                    width: "100%",
                    maxHeight: "100px",
                    objectFit: "cover",
                    display: "block",
                    margin: "20px auto",
                  }}
                />
                <Box
                  sx={{
                    padding: "8px",
                    backgroundColor: "#f9f9f9",
                    fontSize: "12px",
                    color: "#000000",
                    textAlign: "center",
                  }}
                >
                  {video.filename || "Unknown filename"}
                </Box>

                {/* Manage-mode tools: pencil & bin */}
                {manageMode && !isBeingEdited && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: 1,
                      marginTop: "6px",
                    }}
                  >
                    {/* Pencil (Edit) icon */}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnboardEdit(video);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>

                    {/* Bin (Delete) icon */}
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnboardDeleteVideo(video.document_id);
                      }}
                    >
                      <DeleteIcon fontSize="small" color="error" />
                    </IconButton>
                  </Box>
                )}

                {/* If we're renaming a video, show inline input */}
                {isBeingEdited && (
                  <Box sx={{ p: 1, backgroundColor: "#fff" }}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={newVideoName}
                      onChange={(e) => setNewVideoName(e.target.value)}
                      onClick={(e) => e.stopPropagation()} // Prevent closing
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          handleRenameVideo();
                        }
                      }}
                    />
                    <Box
                      sx={{ display: "flex", gap: 1, mt: 1, justifyContent: "flex-end" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRenameVideo();
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setVideoBeingEdited(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>

            );
          })}
        </Box>

      </>

    );

  };

  /**
   * TELEMETRY — handle clicks
   */
  const handleFileClick = (fileId) => {
    setSelectedFileId((prevSelectedId) =>
      prevSelectedId === fileId ? null : fileId
    );
  };

  /**
   * TELEMETRY — delete
   */
  const handleDeleteClick = (file) => {
    setIsLoading(true); // Show loader during deletion
    return HttpProvider.delete(`/delete_session_by_id`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
      params: {
        upload_id: file._id,
      },
    })
      .then((res) => {
        // Remove from local state
        setFiles((prevFiles) => prevFiles.filter((f) => f._id !== file._id));
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
      })
      .finally(() => {
        // toast.success("File deleted", { autoClose: 3000 });
        setIsLoading(false);
      });
  };

  /**
   * TELEMETRY — rename (start editing)
   */
  const handleEditClick = (file, e) => {
    e.stopPropagation();
    setEditFileId(file._id);
    setEditNameValue(file.Name);
  };
  const handleTelemetryEditClick = (file) => {
    setFileToEdit(file);

    // Pre-load the dialog fields
    setTempFileName(file.Name || "");
    setTempFileSession(file.session || "");
    setTempFileRace(file.race || "");
    setTempFileDriver(file.email || "");

    setEditFileDialogOpen(true);
  };
  const saveTelemetryFileEdits = async () => {
    if (!fileToEdit) return;
    try {
      // Name changed? Call update_name_by_id
      if (tempFileName !== fileToEdit.Name) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_name_by_id`,
          null,
          {
            params: {
              upload_id: fileToEdit._id,
              new_name: tempFileName,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
      }

      // Session changed? Call update_session_by_id
      if (tempFileSession !== fileToEdit.session) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_session_by_id`,
          null,
          {
            params: {
              upload_id: fileToEdit._id,
              new_session: tempFileSession, // Must be an int if your backend expects an int
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
      }

      // Race changed? Call update_race_by_id
      if (tempFileRace !== fileToEdit.race) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_track_and_race_by_id`,
          null,
          {
            params: {
              upload_id: fileToEdit._id,
              new_race: tempFileRace,
              new_track: fileToEdit.track // Must match backend's type
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
      }

      // Driver changed? Call update_driver_by_id
      if (tempFileDriver !== fileToEdit.driver) {
        await axios.put(
          `${process.env.REACT_APP_API_DOMAIN}/update_driver_by_id`,
          null,
          {
            params: {
              upload_id: fileToEdit._id,
              new_driver: tempFileDriver,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
      }

      // Close dialog
      setEditFileDialogOpen(false);

      // Update local state so the UI reflects the changes:
      setFiles((prev) =>
        prev.map((f) =>
          f._id === fileToEdit._id
            ? {
              ...f,
              Name: tempFileName,
              session: tempFileSession,
              race: tempFileRace,
              driver: tempFileDriver,
            }
            : f
        )
      );
    } catch (error) {
      console.error("Error saving file edits:", error);
    }
  };


  /**
   * TELEMETRY — rename (finish editing)
   */
  const handleEditSubmit = (file) => {
    setIsLoading(true);
    return HttpProvider.put(`/update_name_by_id`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
      params: {
        upload_id: file._id,
        new_name: editNameValue,
      },
    })
      .then((res) => {
        // Update local name
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f._id === file._id ? { ...f, Name: editNameValue } : f
          )
        );
        setEditFileId(null);
      })
      .catch((error) => {
        console.error("Error updating name:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelEdit = () => {
    setEditFileId(null);
    setEditNameValue("");
  };

  /**
   * Single List Tile for TELEMETRY
   */
  const ListTile = useCallback(
    ({ file }) => {
      const isSelected = selectedFileId === file._id;
      const isEditing = editFileId === file._id;
      const hasError = file.status === "error";


      return (
        <>


          <ListItem
            onClick={() => handleFileClick(file._id)}
            sx={{
              backgroundColor: hasError
                ? "rgba(255, 0, 0, 0.05)"
                : isSelected
                  ? "rgba(0, 0, 255, 0.05)"
                  : "inherit",
              border: hasError
                ? "1px solid red"
                : isSelected
                  ? "1px solid blue"
                  : "none",
              borderRadius: "8px",
              transition: "all 0.3s ease",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: hasError ? "red" : "none" }}>
                <AttachFileIcon />
              </Avatar>
            </ListItemAvatar>

            {isEditing ? (
              <Box onClick={(e) => e.stopPropagation()} sx={{ flexGrow: 1 }}>
                <input
                  type="text"
                  value={editNameValue}
                  onChange={(e) => setEditNameValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleEditSubmit(file);
                    }
                  }}
                  style={{
                    width: "100%",
                    padding: "8px",
                    fontSize: "1rem",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  autoFocus
                />
                <Box mt={1} sx={{ display: "flex", gap: "8px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleEditSubmit(file)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            ) : (
              <ListItemText
                primary={file.Name}
                secondary={
                  hasError
                    ? `FAILED TO UPLOAD: ${file.error_details || "Unknown error"}`
                    : `race: ${file.raceName} | laps: ${file.laps} | session:${file.session} `
                }
                sx={{
                  color: hasError ? "red" : "inherit",
                }}
              />
            )}


            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Help icon for specific error */}
              {hasError &&
                file.error_details === "\"['longitude', 'latitude'] not in index\"" && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setHelpDialogOpen(true);
                    }}
                    sx={{
                      color: "red",
                      visibility: "visible",
                      transition: "visibility 0.2s ease",
                      mr: 1,
                    }}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                )}

              {/* Edit icon (visible only if not error) */}
              {!hasError && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTelemetryEditClick(file); // A new function that opens the dialog
                  }}
                  sx={{
                    color: "gray",
                    visibility: isSelected ? "visible" : "hidden",
                    transition: "visibility 0.2s ease",
                    mr: 1,
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              {hasError && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setContactDialogOpen(true);
                  }}
                  sx={{
                    color: "red",
                    visibility: "visible",
                    transition: "visibility 0.2s ease",
                    mr: 1, // margine a destra
                  }}
                >
                  <SupportAgentIcon />
                </IconButton>
              )}

              {/* Delete icon */}
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(file);
                }}
                sx={{
                  color: "red",
                  visibility: isSelected || hasError ? "visible" : "hidden",
                  transition: "visibility 0.2s ease",
                }}
              >
                <DeleteIcon />
              </IconButton>

              {/* Progress bar */}
              {!hasError && (
                <LinearProgressWithLabel value={file.percentage} status={file.status} />
              )}
            </Box>
          </ListItem>

          <Divider />
        </>
      );
    },
    [selectedFileId, editFileId, editNameValue]
  );

  /**
   * Fetch both Telemetry files & competitions on mount
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. Load TELEMETRY files
        const loadedFiles = await loadFiles();

        // 2. Fetch competitions
        const response = await axios.get(
          `${process.env.REACT_APP_API_DOMAIN}/get_competitions/?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        const competitionsData = response.data;
        setCompetitions(competitionsData);

        // 3. Merge raceName into files
        const updatedFiles = loadedFiles.map((file) => {
          return {
            ...file,
            raceName:
              competitionsData.find((comp) => comp.id === file.race)
                ?.competition || "N/A",
          };
        });

        setFiles(updatedFiles);
        setIsDataReady(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [selectedTeam, editFileDialogOpen]);

  /**
   * Switch tab
   */
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSearchQuery(""); // Reset the search bar
  };

  const isEmptyList = useMemo(() => {
    return files.length === 0 && processing.length === 0;
  }, [files, processing]);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  console.log("chosen track ", chosenTrack)
  return (
    <Box {...props}>
      <Dialog open={helpDialogOpen} onClose={() => setHelpDialogOpen(false)}>
        <DialogTitle>File Upload Error</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            When you export a CSV file from Starlane, make sure the track map is open.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHelpDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
        <Typography variant="h4" component="div">
          Your Files
        </Typography>
        {useLinkToPage ? (
          <Button variant="contained" color="primary">
            Go to the list
          </Button>
        ) : null}
      </Stack>
      <Pricing open={showPricing} onClose={() => setShowPricing(false)} />
      <Box mt={2} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Chip
          label="TELEMETRY"
          variant={activeTab === "TELEMETRY" ? "filled" : "outlined"}
          color="primary"
          onClick={() => handleTabChange("TELEMETRY")}
        />
        <Chip
          label="ON BOARD"
          variant={activeTab === "ON BOARD" ? "filled" : "outlined"}
          color="primary"
          onClick={() => handleTabChange("ON BOARD")}
        />
      </Box>
      <Dialog
        open={openMultipleViewDialog}
        onClose={() => { setOpenMultipleViewDialog(false); setCurrentNote(""); setShowNotes(false) }}
        // Altezza fissa a 80vh, larghezza adattabile (con un limite di 90% viewport)
        PaperProps={{
          sx: {
            height: window.innerWidth < 400 ? '80vh' : '140vh',
            width: window.innerWidth < 400 ? '95%' : window.innerWidth > 800 ? ((showTrackMap || showNotes)) ? '70%' : '45%' : '80%',

            maxWidth: '90vw',
            backgroundColor: '#000',
            borderRadius: 4,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {/* Pulsante "X" per chiudere */}
        {/* <IconButton
          onClick={() => setOpenMultipleViewDialog(false)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: '#fff',
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton> */}
        {/* (((showTrackMap && !showNotes)||(!showTrackMap && showNotes)) */}
        {(showTrackMap || showNotes) && (window.innerWidth > 600) ? (
          <DialogContent
            sx={{
              p: 0,
              backgroundColor: '#000',
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              overflow: 'hidden',
            }}
          >

            <Box sx={{
              p: 0,
              backgroundColor: '#000',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden',
            }}>
              {selectedMultipleVideos.map((videoId, index) => {
                const video = videos.find((v) => v.document_id === videoId);
                const embedUrl = `https://iframe.mediadelivery.net/embed/361126/${video?.video_id}?autoplay=true&loop=false&muted=false&preload=true&responsive=true`;

                // Ogni video occupa una frazione di 100% in altezza,
                // in modo che la somma totale rimanga entro 80vh.
                const perVideoHeight = 100 / selectedMultipleVideos.length;

                return (
                  <Box
                    key={videoId}
                    sx={{
                      width: '100%',
                      height: `${perVideoHeight}%`,
                      position: 'relative',
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedMultipleVideos((prev) => {
                          const newVideos = prev.filter((id) => id !== videoId);
                          if (newVideos.length === 0) {
                            setOpenMultipleViewDialog(false);
                          }
                          return newVideos;
                        });
                      }}
                      sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        color: 'black',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: '2px',
                        zIndex: 3,
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>


                    <iframe
                      src={embedUrl}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 0,
                      }}
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                      allowFullScreen
                    />
                    {index === selectedMultipleVideos?.length - 1 && (
                      <>
                        <IconButton
                          sx={{
                            position: "absolute",
                            bottom: window.innerWidth < 600 ? 80 : 6,
                            left: window.innerWidth < 600 ? 45 : 110,
                            color: "#ffffff",
                            backgroundColor: "#052c65",
                            "&:hover": { backgroundColor: "#041d46" },
                          }}
                          onClick={() => { setShowTrackMap((prev) => !prev); setShowNotes(false) }}
                        >
                          {!showTrackMap ? <MyLocationIcon /> : <LocationSearchingIcon />}
                        </IconButton>

                      </>
                    )}
                    <IconButton
                      sx={{
                        position: "absolute",
                        bottom: window.innerWidth < 600 ? 80 : 6,
                        left: window.innerWidth < 600 ? 2 : 55, // Regola la posizione a piacere
                        color: "#ffffff",
                        backgroundColor: "#052c65",
                        "&:hover": { backgroundColor: "#041d46" },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowNotes(!showNotes);
                        setShowTrackMap(false);
                        setChosenVideo(video); // Assicurati di avere già chosenVideo in state
                        fetchComment(video.document_id);
                      }}
                    >
                      {showNotes ? <CloseIcon /> : <EditNoteIcon />}
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
            {showNotes ? (
              <Box
                sx={{
                  position: "relative",
                  width: window.innerWidth > 600 ? "50%" : "100%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                  border: "2px solid #cccccc",
                  borderRadius: 2,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" sx={{ mb: 0 }}>
                  Video Notes
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={33}
                  variant="outlined"
                  value={currentNote}
                  onChange={(e) => {
                    // 1) Aggiorna lo stato locale
                    setCurrentNote(e.target.value);
                    // 2) Salva subito su backend
                    saveNoteOnChange(e.target.value);
                  }}
                  placeholder="Write here the video notes..."
                />

              </Box>
            ) : (
              showTrackMap && (
                <Box
                  sx={{
                    position: "relative",
                    width: "50%",
                    height: "100%",
                    backgroundColor: "#000000",
                    border: "2px solid #ffffff",
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <TrackMapEarth notSearch={true} differentTrack={chosenTrack} isRecap={true} />
                </Box>
              )
            )}

          </DialogContent>) : (<DialogContent
            sx={{
              p: 0,
              backgroundColor: '#000',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden',
            }}
          >
            {(showNotes && !showTrackMap) ? (
              <Box
                sx={{
                  position: "relative",
                  width: window.innerWidth > 600 ? "50%" : "100%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                  border: "2px solid #cccccc",
                  borderRadius: 2,
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Video Notes
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={7}
                  variant="outlined"
                  value={currentNote}
                  onChange={(e) => {
                    // 1) Aggiorna lo stato locale
                    setCurrentNote(e.target.value);
                    // 2) Salva subito su backend
                    saveNoteOnChange(e.target.value);
                  }}
                  placeholder="Write here the video notes..."
                />

              </Box>
            ) : (
              showTrackMap && (
                <Box
                  sx={{
                    position: "relative",
                    width: window.innerWidth > 600 ? "50%" : "100%",
                    height: "100%",
                    backgroundColor: "#000000",
                    border: "2px solid #ffffff",
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <TrackMapEarth notSearch={true} differentTrack={chosenTrack} isRecap={true} />
                </Box>
              )
            )}



            {selectedMultipleVideos.map((videoId, index) => {
              const video = videos.find((v) => v.document_id === videoId);
              const embedUrl = `https://iframe.mediadelivery.net/embed/361126/${video?.video_id}?autoplay=true&loop=false&muted=false&preload=true&responsive=true`;

              // Ogni video occupa una frazione di 100% in altezza,
              // in modo che la somma totale rimanga entro 80vh.
              const perVideoHeight = 100 / selectedMultipleVideos.length;

              return (
                <>
                  <Box
                    key={videoId}
                    sx={{
                      width: '100%',
                      height: `${perVideoHeight}%`,
                      position: 'relative',
                    }}
                  >

                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedMultipleVideos((prev) => {
                          const newVideos = prev.filter((id) => id !== videoId);
                          if (newVideos.length === 0) {
                            setOpenMultipleViewDialog(false);
                          }
                          return newVideos;
                        });
                      }}
                      sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        color: 'black',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        padding: '2px',
                        zIndex: 3,
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <iframe
                      src={embedUrl}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 0,
                      }}
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                      allowFullScreen
                    />
                    {index === selectedMultipleVideos?.length - 1 && (
                      <>
                        <IconButton
                          sx={{
                            position: "absolute",
                            bottom: window.innerWidth < 600 ? 70 : 6,
                            left: window.innerWidth < 600 ? 45 : 110,
                            color: "#ffffff",
                            backgroundColor: "#052c65",
                            "&:hover": { backgroundColor: "#041d46" },
                          }}
                          onClick={() => setShowTrackMap((prev) => !prev)}
                        >
                          {!showTrackMap ? <MyLocationIcon /> : <LocationSearchingIcon />}
                        </IconButton>

                      </>

                    )}
                    <IconButton
                      sx={{
                        position: "absolute",
                        bottom: window.innerWidth < 600 ? 70 : 6,
                        left: window.innerWidth < 600 ? 2 : 55, // Regola la posizione a piacere
                        color: "#ffffff",
                        backgroundColor: "#052c65",
                        "&:hover": { backgroundColor: "#041d46" },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowNotes(!showNotes);
                        setShowTrackMap(false);
                        setChosenVideo(video); // Assicurati di avere già chosenVideo in state
                        fetchComment(video.document_id);
                      }}
                    >
                      {showNotes ? <CloseIcon /> : <EditNoteIcon />}
                    </IconButton>

                  </Box>
                </>);
            })}
          </DialogContent>

        )}
      </Dialog>
      <Dialog
        open={editFileDialogOpen}
        onClose={() => setEditFileDialogOpen(false)}
        PaperProps={{
          sx: {
            width: { xs: "90%", md: "70%", lg: "50%" },
            // etc.
          },
        }}
      >
        <DialogTitle>Edit File Info</DialogTitle>
        <DialogContent sx={{ mt: 1, display: "flex", flexDirection: "column", gap: 2 }}>
          {/* NAME */}
          <TextField
            label="Name"
            value={tempFileName}
            onChange={(e) => setTempFileName(e.target.value)}
            fullWidth
            sx={{ marginTop: 2 }}
          />

          {/* SESSION (chips 1..6, for example) */}
          <Typography variant="h6" sx={{ mb: 0 }}>
            Session Number:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <Chip
                key={num}
                label={num}
                color="default"
                size="medium"
                variant={tempFileSession === num ? "contained" : "outlined"}
                onClick={() => setTempFileSession(num)}
              />
            ))}
          </Box>

          {/* RACE */}
          <FormControl fullWidth>
            <InputLabel>Race</InputLabel>
            <Select
              value={tempFileRace}
              onChange={(e) => setTempFileRace(e.target.value)}
              label="Race"
            >
              <MenuItem value="">(None)</MenuItem>
              {competitions.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.competition}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* DRIVER */}
          <FormControl fullWidth>
            <InputLabel>Driver</InputLabel>
            <Select
              value={tempFileDriver}
              onChange={(e) => setTempFileDriver(e.target.value)}
              label="Driver"
            >
              <MenuItem value="">(None)</MenuItem>
              {drivers.map((drv) => (
                <MenuItem key={drv.email} value={drv.email}>
                  {drv.surname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditFileDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={saveTelemetryFileEdits}>
            Save
          </Button>
        </DialogActions>
      </Dialog>



      <Box mt={3}>
        {/* SEARCH BAR */}
        <Box mb={2}>
          <TextField
            fullWidth
            label="Search Files"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Type to search files..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: { backgroundColor: "#ffffff", borderRadius: "8px" },
            }}
          />
        </Box>
        {activeTab === "ON BOARD" && filteredVideos.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3 }}>
            {!multipleViewMode && (<Button
              variant={manageMode ? "outlined" : "contained"}
              color="primary"
              onClick={() => setManageMode((prev) => !prev)}
            >
              {manageMode ? "Close Manage" : "Manage"}
            </Button>)}

            <Button
              variant={multipleViewMode ? "outlined" : "contained"}
              sx={{
                backgroundColor: '#052c65',
                color: '#ffffff', // Testo bianco per contrasto
                '&:hover': {
                  backgroundColor: '#041d46', // Colore leggermente più scuro al passaggio del mouse
                }
              }}
              onClick={() => {
                setMultipleViewMode((prev) => {
                  if (prev) {
                    setSelectedMultipleVideos([]);
                  }
                  return !prev;
                });
              }}
            >
              {multipleViewMode ? "Close Multiple View" : "Multiple View"}
            </Button>

            {selectedMultipleVideos.length > 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleMultipleViewClick}
              >
                Open Multiple View
              </Button>
            )}
          </Box>
        )}

        {isLoading ? (
          <CardLoader height={70} times={10} spacing={0.5} />
        ) : activeTab === "TELEMETRY" ? (
          files.length > 0 ? (
            <Box mt={3}>
              {filteredFiles.length > 0 ? (
                <List>
                  {filteredFiles.map((file) => (
                    <Fragment key={file._id}>
                      <ListTile file={file} />
                    </Fragment>
                  ))}
                </List>
              ) : (
                <Typography variant="body1">No files match your search.</Typography>
              )}
            </Box>
          ) : (
            <Typography variant="body1">No file in the list</Typography>
          )
        ) : (
          renderOnboardList()
        )}
      </Box>

      {/* Manage button only for ON BOARD tab */}


      {/* ON BOARD Video Modal */}
      {/* <Dialog
        open={videoLoading}
        onClose={handleCloseVideo}
        PaperProps={{
          sx: {
            backgroundColor: "#000000",
            width: "100%",
            height: showTrackMap
              ? { xs: "100vh", sm: "100vh", lg: "100vh" }
              : { xs: "46vw", sm: "46vw", lg: "46%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "16px",
          },
        }}
      >
        {status === 7 ? (
          // STATO 7: file non supportato
          <Dialog
            open={videoLoading}
            onClose={handleCloseVideo}
            PaperProps={{
              sx: {
                backgroundColor: "#000000",
                display: "flex",
                flexDirection: "row",
                width: { xs: "95%", sm: "90%", lg: "80%" },
                height: showTrackMap ? "100vh" : "80vh",
                borderRadius: "16px",
              },
            }}
          >
            <Box
              sx={{
                color: "#ffffff",
                fontSize: "1.2rem",
                textAlign: "center",
                padding: "20px",
                backgroundColor: "rgba(255, 0, 0, 0.7)",
                borderRadius: "8px",
                maxWidth: "80vw",
                height: showTrackMap ? "100vh" : "100%",
                paddingTop: 20,
              }}
            >
              File type not supported.<br />
              Try uploading an MP4 or .mov file, or contact <strong>support@aimotor.eu</strong>.
            </Box>
          </Dialog>
        ) : status !== 2 && status !== 1 ? (
          // Stato “pronto” = iframe
          <>
            {showTrackMap && (
              <Box
                sx={{
                  position: "relative",
                  // o dove preferisci

                  width: '100%',// dimensioni a piacere
                  height: '90%',
                  backgroundColor: "#000000",
                  border: "2px solid #ffffff",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <TrackMapEarth notSearch={true} differentTrack={chosenTrack} isRecap={true} />
              </Box>
            )}
            <iframe
              src={embedUrl}
              loading="lazy"
              style={{
                border: 0,
                width: "100%",
                height: "100%",
                maxWidth: "90vw",
                maxHeight: "70vh",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
              allowFullScreen
              title="Video Player"
            />

           
            <IconButton
              sx={{
                position: "absolute",
                bottom: window.innerWidth < 600 ? showTrackMap ? 275 : 130 : 6,
                left: window.innerWidth < 600 ? 2 : 55,
                color: "#ffffff",
                backgroundColor: "#052c65",
                "&:hover": {
                  backgroundColor: "#041d46",
                },
              }}
              onClick={() => setShowTrackMap((prev) => !prev)}
            >
              {!showTrackMap ? <MyLocationIcon /> : <LocationSearchingIcon />}
            </IconButton>

     

          </>
        ) : (
          // Stato 1 o 2: video in elaborazione
          <Box
            sx={{
              color: "#ffffff",
              fontSize: "1.2rem",
              textAlign: "center",
              padding: "20px",
            }}
          >
            Video processing...
          </Box>
        )}
      </Dialog> */}

      <Dialog open={contactDialogOpen} onClose={() => setContactDialogOpen(false)} maxWidth="sm" fullWidth>
        <ContactInside />
      </Dialog>
    </Box>

  );
}

export default observer(FilesRecap);
