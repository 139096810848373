import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SpeedIcon from '@mui/icons-material/Speed';
import CompareIcon from '@mui/icons-material/Compare';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import TuneIcon from '@mui/icons-material/Tune';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import Chip from '@mui/material/Chip';

const itemsRow1 = [
  {
    icon: <PrecisionManufacturingIcon />,
    text: 'Corner Comparison',
    detailedMessage: "Provide a detailed comparison of Member 1's Lap 6 from Session 1 with My Lap 9 from Session 3, focusing specifically on Sector 4.",
  },
  {
    icon: <SwapHorizIcon />,
    text: 'Axle position change',
    detailedMessage: "Should I change the axle position for the next session?",
  },
  {
    icon: <SpeedIcon />,
    text: 'Fastest driver setups',
    detailedMessage: "Who was the fastest driver on my team yesterday, and what setups did they use?",
  },
  {
    icon: <CompareIcon />,
    text: 'Sector 3 time comparison',
    detailedMessage: "Why am I slower than my teammate in Sector 3?",
  }
];

const itemsRow2 = [
  {
    icon: <DirectionsCarIcon />,
    text: 'Sector 5 exit speeds',
    detailedMessage: "Can you show me my sector 5 exit speeds for the last session?",
  },
  {
    icon: <TrackChangesIcon />,
    text: 'Braking points in Sector 4',
    detailedMessage: "Can you analyze my braking points in Sector 4?",
  },
  {
    icon: <ElectricBoltIcon />,
    text: 'Max RPM with sprocket and pinion',
    detailedMessage: "With which sprocket and pinion did I hit max RPM yesterday?",
  },
  {
    icon: <TuneIcon />,
    text: 'Fastest setup for sector 4',
    detailedMessage: "With what setup have I made sector 4 the fastest today?",
  }
];

const itemsRow3 = [
  {
    icon: <TireRepairIcon />,
    text: 'Rear grip improvement',
    detailedMessage: "I struggled with rear grip in corners. Considering my data and new tires in 25°C, what changes do you suggest?",
  },
  {
    icon: <SpeedIcon />,
    text: 'Fastest driver setups',
    detailedMessage: "Who was the fastest driver on my team yesterday, and what setups did they use?",
  },
  {
    icon: <ElectricBoltIcon />,
    text: 'Max RPM with sprocket and pinion',
    detailedMessage: "With which sprocket and pinion did I hit max RPM yesterday?",
  },
  {
    icon: <TrackChangesIcon />,
    text: 'Braking points in Sector 4',
    detailedMessage: "Can you analyze my braking points in Sector 4?",
  }
];

const Infinitescroll = () => {
  const history = useHistory();
  const [isPaused1, setIsPaused1] = useState(false);
  const [isPaused2, setIsPaused2] = useState(false);
  const [isPaused3, setIsPaused3] = useState(false);
  const [hoveredIndexRow1, setHoveredIndexRow1] = useState(null);
  const [hoveredIndexRow2, setHoveredIndexRow2] = useState(null);
  const [hoveredIndexRow3, setHoveredIndexRow3] = useState(null);

  const current1 = useRef(0);
  const current2 = useRef(0);
  const current3 = useRef(0);

  useEffect(() => {
    const scrollRow = (rowId, currentRef, isPaused, direction) => {
      if (!isPaused) {
        const rowElement = document.getElementById(rowId);
        if (rowElement) {
          currentRef.current += direction;
          const rowWidth = rowElement.scrollWidth;
          const containerWidth = rowElement.parentElement.offsetWidth;

          if (direction === -1 && currentRef.current <= -rowWidth / 3) {
            currentRef.current = 0;
          } else if (direction === 1 && currentRef.current >= 0) {
            currentRef.current = -rowWidth / 3;
          }

          rowElement.style.transform = `translateX(${currentRef.current}px)`;
        }
      }
    };

    const interval1 = setInterval(() => {
      scrollRow("row-1", current1, isPaused1, -1);
    }, 20);

    const interval2 = setInterval(() => {
      scrollRow("row-2", current2, isPaused2, 1);
    }, 20);

    const interval3 = setInterval(() => {
      scrollRow("row-3", current3, isPaused3, -1);
    }, 20);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, [isPaused1, isPaused2, isPaused3]);

  const handleMouseEnter = (row, index) => {
    if (row === 1) {
      setIsPaused1(true);
      setHoveredIndexRow1(index);
    }
    if (row === 2) {
      setIsPaused2(true);
      setHoveredIndexRow2(index);
    }
    if (row === 3) {
      setIsPaused3(true);
      setHoveredIndexRow3(index);
    }
  };

  const handleMouseLeave = (row) => {
    if (row === 1) {
      setIsPaused1(false);
      setHoveredIndexRow1(null);
    }
    if (row === 2) {
      setIsPaused2(false);
      setHoveredIndexRow2(null);
    }
    if (row === 3) {
      setIsPaused3(false);
      setHoveredIndexRow3(null);
    }
  };

  const handleChipClick = (item) => {
    //history.push(`/racing-space-try/chat-bot?question=${encodeURIComponent(item.detailedMessage)}`);
    history.push(`/sign-up`);
  };

  return (
    <div style={styles.container}>
      <div id="row-1" style={styles.row}>
        {itemsRow1.concat(itemsRow1, itemsRow1).map((item, index) => (
          <Chip
            key={index}
            icon={item.icon}
            label={item.text}
            onClick={() => handleChipClick(item)}
            onMouseEnter={() => handleMouseEnter(1, index)}
            onMouseLeave={() => handleMouseLeave(1)}
            deleteIcon={<ArrowOutwardIcon />}
            onDelete={() => {}}
            sx={{
              ...styles.chip,
              ...(hoveredIndexRow1 === index ? styles.chipHovered : {}),
            }}
          />
        ))}
      </div>
      <div id="row-2" style={styles.row}>
        {itemsRow2.concat(itemsRow2, itemsRow2).map((item, index) => (
          <Chip
            key={index}
            icon={item.icon}
            label={item.text}
            onClick={() => handleChipClick(item)}
            onMouseEnter={() => handleMouseEnter(2, index)}
            onMouseLeave={() => handleMouseLeave(2)}
            deleteIcon={<ArrowOutwardIcon />}
            onDelete={() => {}}
            sx={{
              ...styles.chip,
              ...(hoveredIndexRow2 === index ? styles.chipHovered : {}),
            }}
          />
        ))}
      </div>
      <div id="row-3" style={styles.row}>
        {itemsRow3.concat(itemsRow3, itemsRow3).map((item, index) => (
          <Chip
            key={index}
            icon={item.icon}
            label={item.text}
            onClick={() => handleChipClick(item)}
            onMouseEnter={() => handleMouseEnter(3, index)}
            onMouseLeave={() => handleMouseLeave(3)}
            deleteIcon={<ArrowOutwardIcon />}
            onDelete={() => {}}
            sx={{
              ...styles.chip,
              ...(hoveredIndexRow3 === index ? styles.chipHovered : {}),
            }}
          />
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
    padding: "25px 5px",
    backgroundColor: "black",
  },
  row: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "center",
  },
  chip: {
    margin: '0 10px',
    marginTop: '12px',
    backgroundColor: "#171717",
    color: "white",
    cursor: "pointer",
    fontSize: "1.2em",   // Increased font size
    padding: '16px 20px', // Increased padding
    height: 'auto',
    '& .MuiChip-icon': {
      color: 'white',
      fontSize: '1.5em', // Increased icon size
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
      fontSize: '1.5em', // Increased arrow icon size
    },
  },
  chipHovered: {
    textDecoration: "underline",
  },
};

export default Infinitescroll;
